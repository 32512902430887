import React, { Component } from 'react'
import { Form ,Button} from 'antd'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import './index.css'
export default class SearchView extends Component {
    state={
        show:false
    }
    //清空
    onReset = (namearr) => {
        //disreset  除了disreset，清空其它的
        const {disreset} = this.props
        if(disreset){
            const fieldsValue = this.form.getFieldsValue();
            console.log("onReset-fieldsValue",fieldsValue)
            const fieldNames = Object.keys(fieldsValue);
            console.log("fieldNames",fieldNames)
            const result = fieldNames.filter((item) => !disreset.includes(item));
            console.log("onReset-result",result)
            this.form.resetFields(result);
            if(disreset.includes("article_category_id")){
                fieldsValue.article_category_id.pop()
                this.form.setFieldsValue({
                    article_category_id: fieldsValue.article_category_id
                  });
            }
            return

        }
        if(namearr)
        this.form.resetFields(namearr);
        else
        this.form.resetFields();
    };

  render() {
      const {onSearch,validateTrigger=''} = this.props
    return (
      <div className="search-tools-view">
        <Form
        validateTrigger={validateTrigger}
                    ref={e => this.form = e}
                    name="customized_form_controls"
                    layout="inline"
                    onFinish={(e)=>onSearch&&onSearch(e)}
                // initialValues={{
                //   name: 1,
                // }}
                >

                    <div className='tools-from-box'>
                        {
                            this.props.children
                        }








                        {/* <Form.Item
                name="first_time_rang"
                label="首检日期"
                style={{ marginRight: 50 }}
              >
                <RangePicker
             
                />
              </Form.Item> */}

                    </div>

                    <div className='tools-btn-box'>
                        <Form.Item
                        >
                            {
                                onSearch?
                                <>
                                <Button  type="primary" htmlType="submit" 
                            // icon={<SearchOutlined />}
                            >
                                搜索
                            </Button>
                               
                            
                            <Button  
                            // icon={<DeleteOutlined />}
                                onClick={()=>this.onReset()}
                            >
                                重置
                            </Button>
                            </>
                             :
                             null
                         }
                            {
                                this.props.otherButton
                            }
                        </Form.Item>
                    </div>



                </Form>
      </div>
    )
  }
}
