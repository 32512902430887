
import React, { Component, useState, useEffect } from 'react';
import LeftMenu from '../menus';
import App from '../../App';
import { Settings } from '../../config/defaultSetting';
import { Layout, theme, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, LoginOutlined } from '@ant-design/icons';
import { modLoginOut } from '../../common/tools/ex_login';
import { Routes, Route, Navigate } from 'react-router';
import { TOKEN } from '../../common/utils/contans';
import { getStorage } from '../../common/utils/storage.ts';
import { connect } from 'react-redux';
import { setPageTitle, setUserInfo } from '../../store/actions'
import PlugData from '../../pages/datalist/plugdata';
import ArticleView from '../../pages/configs/article';
import Cookies from 'js-cookie';
import ArticleEditAdd from '../../pages/configs/article/article_edit_add';
import Userlist from '../../pages/configs/adminAccount';
import ArticleTags from '../../pages/configs/articleTags';
import LeadTeam from '../../pages/configs/lead_team';
import Recruitment from '../../pages/configs/recruitment';
import Resumedelivery from '../../pages/datalist/resumedelivery';
import { NetGet } from '../../axios_tools';
import { APIS } from '../../config/api';
import Logs from '../../pages/datalist/logs';
const { Header, Sider, Content } = Layout;

function ComLayOut(props) {


    const [collapsed, setCollapsed] = useState(false)

    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const token = Cookies.get("Authorization");

    useEffect(() => {
        if (token) {
            props.setUserInfo()
        }
        // getUserInfo()
    }, [])




    const Permissions = ({ children }) => {
        console.log({ token })
        return token ? children : <Navigate to="/login" />;
    };

    const user_name = Cookies.get("user_name")


    function getUserInfo() {
        NetGet(APIS.adminAccount.userinfo)
            .then(() => {

            })
    }


    console.log({ props })
    return <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}
            className="left-menus-sider"
            style={{
                padding: 0,
                background: colorBgContainer,
            }}
        >
            <div className="menu-logo" style={{}}>
                <span>{Settings.title}</span>
            </div>
            <LeftMenu history={props.history} userInfo={props.userInfo}/>
        </Sider>
        <Layout className="site-layout">
            <Header
                style={{
                    padding: 0,
                    background: colorBgContainer,
                    zIndex: 3,
                    boxShadow: '0 3px 5px rgba(0,0,0,0.1)'
                }}
            >
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: () => setCollapsed(!collapsed),
                })}
                <div className="header-tools">
                    <div className="login-out-div">
                        <Tooltip title="退出登录">
                            <span className="login-out-icon hover-color-bg"
                                onClick={modLoginOut}
                            // onClick={()=>props.setPageTitle(123)} //redux 更新
                            >
                                <LoginOutlined style={{
                                    color: "rgba(0,0,0,0.6)",
                                    fontSize: "16px"
                                }} />
                            </span>
                        </Tooltip>
                    </div>

                    <div className="user-info hover-color-bg">
                        <div className="user-head"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'center',
                                color: "#003bb4"
                            }}
                        >{user_name ? user_name.substr(0, 1) : ''}</div>
                        <div className="user-name">{user_name}</div>
                    </div>
                </div>
            </Header>
            <div
                style={{
                    margin: '24px 16px',
                    padding: 24,
                    // minHeight: 280,
                    background: colorBgContainer,
                }}
            >
                <Routes>
                    <Route path="/" element={<ArticleView history={props.history} />}></Route>

                    <Route path="/datas/article" element={<ArticleView history={props.history} />}></Route>
                    <Route path="/artTag/:tagFloorIndex/:pageName" element={<ArticleView history={props.history} />}></Route>

                    <Route path="/datas/articleEditAdd" element={<ArticleEditAdd history={props.history} />}></Route>


                    <Route path="/datas/articleTags" element={<ArticleTags history={props.history} />}></Route>

                    {
                        props.userInfo&&props.userInfo.role_id=='admin'?
                            <>
                                <Route path="/config/recruitment" element={<Recruitment />}></Route>
                                <Route path="/config/leadteam" element={<LeadTeam />}></Route>
                                <Route path="/datalist/resumedelivery" element={<Resumedelivery />}></Route>

                                <Route path="/config/userlist" element={<Userlist />}></Route>
                                <Route path="/datalist/logs" element={<Logs />}></Route>
                                
                            </>
                            :
                            null
                    }


                    {
                         props.userInfo&&<Route path="*" element={<Navigate to="/login" />}></Route>
                    }

                    {/* <Route path="*" element={<Navigate to="/login" />}></Route> */}
                    {/* <Route path="/login"  element={<Login />}></Route> */}


                    {/* <Route path="/datalist/plugdata" element={<PlugData />}></Route> */}

                </Routes>
            </div>
        </Layout>
    </Layout>
}


export default connect(
    state => {
        return {
            ...state,  //全部属性

            // name: state.name, //指定属性
            // age: state.age

            sex: 1      //添加自定义属性

        }
    },
    {
        setPageTitle: setPageTitle,
        setUserInfo: setUserInfo
    }
)(ComLayOut)