import React from 'react';
import { Modal, Tabs, } from 'antd';

import Draggable from 'react-draggable';
import './index.css'
const { TabPane } = Tabs;
export default class MyDraggModal extends React.Component {
  state = {
    isModalVisible: false,
    confirmLoading: false,
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  }
  draggleRef = React.createRef();
  formRef = React.createRef();
  setIsModalVisible = (e, call) => {
    this.setState({
      isModalVisible: e
    }, () => {
      this.props.onModalChange && this.props.onModalChange(e)
      call && call()
    })
  }

  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.draggleRef.current ?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    // console.log(document.querySelector('.react-draggable'))
    // document.querySelector('.react-draggable').style.transform=`translate(${-targetRect.left + uiData.x}px, -9px)`
    this.setState({
      bounds: {
        left: -targetRect.left + uiData.x - 800,
        right: clientWidth - (targetRect.right - uiData.x) + 800,
        top: -targetRect.top + uiData.y - 400,
        bottom: clientHeight - (targetRect.bottom - uiData.y) + 400,
      },
    });
  };

  showModal = (callback) => {
    this.setIsModalVisible(true, () => {
      callback && callback()
    });
  };



  handleCancel = () => {
    this.props.handleCancel && this.props.handleCancel()

    this.setIsModalVisible(false);
  };

  callback = () => {

  }


  setConfirmLoading = (e) => {
    this.setState({
      confirmLoading: e
    })
  }


  handleOk = async () => {

    this.props.handleOk()
  }

  render() {
    const { confirmLoading, isModalVisible, bounds, disabled, } = this.state
    const { title, width, okText, bodyStyle = {}, contentStyle = {} } = this.props
    return (
      <Modal
        className='drg-modal'
        maskClosable={false}
        {...this.props}
        // title="添加规则"
        // okText={okText?okText:""}
        width={width || 1300}
        bodyStyle={{
          overflowY: "auto",
          maxHeight: "70vh",
          ...bodyStyle
        }}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
              ...this.props.titleStyle
            }}
            onMouseOver={() => {
              if (disabled) {
                this.setState({
                  disabled: false,
                });
              }
            }}
            onMouseOut={() => {
              this.setState({
                disabled: true,
              });
            }}

          // end
          >
            {title}
          </div>
        }
        modalRender={modal => (
          <Draggable
            disabled={disabled}
            // onDrag={(event, uiData) => this.onStart(event, uiData)}
            bounds={bounds}//限制拖动区域
            onStart={(event, uiData) => this.onStart(event, uiData)}
          >
            <div ref={this.draggleRef}>{modal}</div>
          </Draggable>
        )}
        // mask={false}
        
        centered
        visible={isModalVisible}
        onOk={this.handleOk}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}>
        <div className='my-dragg-modal-content' contentStyle={{ ...contentStyle }}>

          {
            this.props.children
          }
        </div>

      </Modal>
    )
  }
}