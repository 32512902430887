import { LockOutlined, UserOutlined, KeyOutlined } from "@ant-design/icons";
import { Avatar, Button, Checkbox, Form, Input, Space, message } from "antd";
import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// import { AuthContext } from "@/common/context";
import { Settings } from "../../config/defaultSetting";
import Cookies from 'js-cookie';
import styles from "./index.module.scss";
import { setStorage } from "../../common/utils/storage.ts";
import { TOKEN } from "../../common/utils/contans";
import { setCookie, removeCookie, getCookie } from "../../common/utils/cookieuit";
import { NetPost, NetGet } from "../../axios_tools";
import { APIS } from "../../config/api";

const Login = () => {
  // const { signIn } = useContext(AuthContext);
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [captchaUrl, setCaptchaUrl] = useState('');
  const [showCapInput, setShowCapInput] = useState(false)
  const [form] = Form.useForm();
  let isPostLogin = useRef('')

  console.log(form)

  // 下一次登录将cookie填充表单
  const remember = () => {
    if (getCookie("user_name") != "" && getCookie("password") != "") {
      if (form.setFieldsValue) {
        form.setFieldsValue({    //注意是setFieldsValue，不是setFieldValue!!!本人踩雷了T^T
          user_name: getCookie("user_name"),
          password: getCookie("password"),
        });
        if (!showCapInput) {
          setShowCapInput(true)
          getCaptchaAxios()
        }
      }
    }
  };



  useEffect(() => {

    // form&&form.setFieldsValue({    //注意是setFieldsValue，不是setFieldValue!!!本人踩雷了T^T
    //   user_name: '',
    //   password:'',
    // });

    remember()
  }, [])




  const getCaptchaAxios = () => {
    setCaptchaUrl('')
    NetGet(APIS.adminAccount.captcha)
      .then((res) => {
        if (res.code == 0) {
          setCaptchaUrl(res.data.captcha)
        } else {
          message.error("验证码获取失败，请重试")
          console.log("getCaptchaAxios-失败",res)
          setCaptchaUrl('-1')
        }
      }).catch((error) => {
        console.log("error",error)
        // error.__hasCaught = true;
        message.error("验证码获取失败，请重试")
        setCaptchaUrl('-1')
      })
  }


  const onFinish = async (values) => {
    if(isPostLogin.current){
      return false
    }
    try {
      console.log("登录表单", values)
      isPostLogin.current=true
      setLoading(true);
      NetPost(APIS.adminAccount.login, values)
        .then((result) => {
          
          if(result.code!=0){
            isPostLogin.current=false
            setLoading(false);
            message.error(result.message)
           return getCaptchaAxios()
          }
          message.success("登录成功")
          Cookies.set('Authorization', result.data.token, { expires: 1000 })
          Cookies.set('user_name', values.user_name, { expires: 1000 })
          
          setTimeout(()=>{
            isPostLogin.current=false
            setLoading(false);
            navigator("/");
          },300)
        }).catch(()=>{
          getCaptchaAxios()
          isPostLogin.current=false
          setLoading(false);
        })


      // const token = JSON.stringify(values);
      // setStorage(TOKEN,"123")
      // await signIn(dispatch, token);
      // navigator("/");
    } finally {
      
    }
  };




  return (
    <div id={styles.loginContainer}>
      <div className={styles.loginTop}>
        <h2>{Settings.title}</h2>
        <Avatar src={Settings.logo} />
      </div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        size="large"
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name="user_name"
          rules={[{ required: true, message: "请输入账号!" }]}
        >
          <Input
            allowClear
            prefix={<UserOutlined />}
            placeholder="登录账号"
            onChange={() => {
              if (!showCapInput) {
                setShowCapInput(true)
                getCaptchaAxios()
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "请输入密码!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            placeholder="登录密码"
          />
        </Form.Item>

        {
          showCapInput ?
            <Space className={styles.captchaView}>
              <Form.Item
                name="captcha"
                rules={[{ required: true, message: "请输入密码!" }]}
                style={{
                  width: "228"
                }}
              >
                <Input
                  prefix={<KeyOutlined />}
                  placeholder="请输入验证码"
                />
              </Form.Item>

              <Form.Item>

                <Button
                  onClick={getCaptchaAxios}
                  loading={!captchaUrl}
                  style={{
                    height: 40,
                    width: 92,
                    cursor: 'pointer',
                    outline: 'none'
                  }}
                >
                  {
                    captchaUrl ?
                      (captchaUrl === '-1'
                        ?
                        "获取验证码"
                        :
                        <img src={captchaUrl}
                          style={{
                            height: '100%',
                            width: '100%',
                            display: "inline-block"
                          }}
                        />
                      )
                      :
                      null
                  }




                </Button>
              </Form.Item>

            </Space>
            :
            null
        }


        {/* <Form.Item>
          <Form.Item noStyle name="remember" valuePropName="checked">
            <Checkbox>记住密码</Checkbox>
          </Form.Item>

          <a className="login-form-forgot" href="#">
            忘记密码
          </a>
        </Form.Item> */}

        <Form.Item>
          <Button
            block
            type="primary"
            htmlType="submit"
            className={styles.loginFormButton}
            loading={loading}
          >
            <div className='ffff'>登录</div>
          </Button>
          {/* 或{" "}
          <a
            onClick={() => {
              form.resetFields();
            }}
          >
            前往注册!
          </a> */}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;