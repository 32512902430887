import React, { Component } from 'react'
import { Space, Table, Tag, Form,DatePicker } from 'antd';
import SearchView from '../../../compontent/serchview';

// import AddYongHuModal from './addModal';
const { RangePicker } = DatePicker;

export default class PlugData extends Component {
    state={
        // income:''
    }

    onSearch=()=>{
        alert("搜索")
    }

  render() {
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Age',
          dataIndex: 'age',
          key: 'age',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
          title: 'Tags',
          key: 'tags',
          dataIndex: 'tags',
          render: (_, { tags }) => (
            <>
              {tags.map((tag) => {
                let color = tag.length > 5 ? 'geekblue' : 'green';
                if (tag === 'loser') {
                  color = 'volcano';
                }
                return (
                  <Tag color={color} key={tag}>
                    {tag.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Space size="middle">
              <a>Invite {record.name}</a>
              <a>Delete</a>
            </Space>
          ),
        },
      ];
      const data = [
        {
          key: '1',
          name: 'John Brown',
          age: 32,
          address: 'New York No. 1 Lake Park',
          tags: ['nice', 'developer'],
        },
        {
          key: '2',
          name: 'Jim Green',
          age: 42,
          address: 'London No. 1 Lake Park',
          tags: ['loser'],
        },
        {
          key: '3',
          name: 'Joe Black',
          age: 32,
          address: 'Sidney No. 1 Lake Park',
          tags: ['cool', 'teacher'],
        },
      ];
    return (
      <div className="pages-datalist-plugdata">

<SearchView
                    // otherButton={
                    //     <Button
                    //         type="primary"
                    //     // onClick={()=>{}}
                    //     ><Link to='/content/wenzhangEditOrAdd'>新增文章</Link></Button>
                    // }
                    onSearch={this.onSearch}
                >
                    

                    <Form.Item
                        name="rangedate"
                        label="时间"
                        // style={{ marginLeft: 50 }}
                    >
                        <RangePicker
                        
                            value={''}
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => setDates(val)}
                        // onChange={val => setValue(val)}
                        // onOpenChange={onOpenChange}
                        />
                    </Form.Item>

                    {/* <div style={{
                        display:"flex",
                        alignItems:"center",
                        marginLeft: 50
                    }}>收益合计：{income}</div> */}

                    {/* <Form.Item label="" style={{ width: "250px" }} name="title">
                        <Input placeholder="请输入文章标题" />
                    </Form.Item> */}

                </SearchView>


        <Table columns={columns} dataSource={data} />
      </div>
    )
  }
}
