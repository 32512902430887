import React from 'react';
import { Select, Form, Input, message, Checkbox, Row, Upload, InputNumber } from 'antd'
import './index.css'
import MyDraggModal from '../../../../compontent/myDraggModal';
import { APIS } from '../../../../config/api';
import Cookies from 'js-cookie';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { NetPost, NetPatch } from '../../../../axios_tools';
const { Option } = Select;
const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e ?.fileList;
};
export default class AdmAccFormModal extends React.Component {
    state = {
        fileList: [],
        cover_image: '',
        imageUrl: '',
        loading: false
    }
    formRef = React.createRef()

  


    showModal = (initialValues) => {
        this.MyDraggModal.showModal(() => {

            if (initialValues) {
                this.formRef.current.resetFields();

                console.log(initialValues)
                this.initialValues = {...initialValues}
                var defaultAvatar = initialValues.avatar

                if (this.initialValues.avatar) {
                    this.initialValues.avatar = [
                        {
                            status: 'done',
                            url: this.initialValues.avatar,
                            thumbUrl: this.initialValues.avatar,
                        }
                    ]
                } else {
                    this.initialValues.avatar = []
                }


                this.formRef.current.setFieldsValue(this.initialValues)
                this.setState({
                    isEdit: true,
                    cover_image: defaultAvatar
                })
            } else {
                this.formRef.current.resetFields();

                this.setState({
                    isEdit: false
                })
            }
        })

        console.log("initialValues", initialValues)


    }


    setConfirmLoading = (e) => {
        this.MyDraggModal && this.MyDraggModal.setConfirmLoading(e)
    }







    handleOk = () => {

        this.formRef.current
            .validateFields()
            .then((values) => {

                var data = values
                console.log(data)
                this.setConfirmLoading(true)
                data.isEdit = this.state.isEdit
                if (data.isEdit)
                    data.id = this.initialValues.id

                const { cover_image } = this.state
                    data.avatar = cover_image
                var NetObj = data.isEdit ? NetPatch : NetPost
                var NetUrl = data.isEdit ? APIS.adminAccount.edit : APIS.adminAccount.add
                NetObj(NetUrl, data)
                    .then((res) => {
                        if (res.code == 0) {
                            if (data.isEdit) {
                                this.props.setTabNowPageRefresh()
                            } else {
                                this.props.getDataOnStart()
                            }
                            this.MyDraggModal && this.MyDraggModal.handleCancel()
                            message.success(data.isEdit ? "修改成功" : "添加成功")
                        } else {
                            message.error(res.message)
                        }
                        this.setConfirmLoading(false)
                    })
                    .catch(() => {
                        this.setConfirmLoading(false)
                    })


            })
    }

  

    render() {
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const { userdata } = this.props
        const { isEdit, fileList, imageUrl, loading } = this.state
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    点击上传
                  </div>
            </div>
        );
        console.log({ isEdit })
        return (
            <MyDraggModal
                title={(isEdit ? "编辑" : "添加") + "账号"}
                width={650}
                handleOk={() => this.handleOk()}
                handleCancel={()=>{
                    // this.formRef.current.resetFields()
                    this.setState({
                        fileList: [],
                        cover_image: '',
                        imageUrl: '',
                        loading: false
                    })
                
                }}
                ref={e => this.MyDraggModal = e}>
                <Form
                    {...layout}
                    ref={this.formRef}
                    className="guize_peizhi_modal"
                    initialValues={{ username: "default" }}
                    style={{
                        marginTop: "30px"
                    }}
                >

                    <Form.Item
                        label="账号"
                        name="user_name"
                        rules={[{ required: true, message: '必须账号' },]}
                    // {
                    //     pattern: /^[^\s]*$/,
                    //     message: '禁⽌输⼊空格',
                    //     }]}
                    >
                        <Input style={{ width: 300 }} placeholder={"请输账号"} />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[{ required: true, message: '必须密码' }]}
                    // {
                    //     pattern: /^[^\s]*$/,
                    //     message: '禁⽌输⼊空格',
                    //     }]}
                    >
                        <Input style={{ width: 300 }} placeholder={"请输入密码"} />
                    </Form.Item>


                    <Form.Item
                        label="昵称"
                        name="nick_name"
                        rules={[{ required: true, message: '必须填写昵称' }]}
                    // {
                    //     pattern: /^[^\s]*$/,
                    //     message: '禁⽌输⼊空格',
                    //     }]}
                    >
                        <Input style={{ width: 300 }} placeholder={"请输入昵称"} />
                    </Form.Item>


                    
                    <Form.Item
                        label="权限"
                        name="role_id"
                        rules={[{ required: true, message: '必须选择权限' }]}
                    // {
                    //     pattern: /^[^\s]*$/,
                    //     message: '禁⽌输⼊空格',
                    //     }]}
                    >
                        <Select placeholder='请选择权限'
                            style={{
                                width:120
                            }}
                        >
                            {
                                this.props.rolelist.map((item)=>{
                                return <Select.Option value={item.role_id}>{item.name}</Select.Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                   

                    <Form.Item
                        label="邮箱"
                        name="email"
                        validateTrigger='onBlur'

                        rules={[
                            {
                              type: 'email',
                            },
                          ]}
                    // {
                    //     pattern: /^[^\s]*$/,
                    //     message: '禁⽌输⼊空格',
                    //     }]}
                    >
                        <Input style={{ width: 300 }} placeholder={"请输入邮箱"} />
                    </Form.Item>

                    <Form.Item
                        label="手机号"
                        name="mobile"
                    // {
                    //     pattern: /^[^\s]*$/,
                    //     message: '禁⽌输⼊空格',
                    //     }]}
                    >
                        <Input style={{ width: 300 }} placeholder={"请输入手机号"} />
                    </Form.Item>


                    <Form.Item
                        label="状态"
                        style={{   }} name="status"
                        initialValue={1}
                        >
                        <Select
                            // allowClear
                            placeholder='状态'
                            style={{
                                width:80
                            }}
                        >
                            <Select.Option value={1}>启用</Select.Option>
                            <Select.Option value={2}>禁用</Select.Option>
                        </Select>
                    </Form.Item>


                    <Form.Item name="avatar" label="头像"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        extra=""
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: '请上传封面',
                    //     },
                    // ]}
                    >
                        <Upload
                            accept="image/*"
                            name='files[]'
                            onRemove ={(file)=>{
                                console.log(file)
                            }}
                            action={APIS.public.uploadFiles}
                            maxCount={1}
                            listType="picture-card"
                            // listType="picture"
                            data={{
                                file_type: 'image'
                            }}
                            headers={
                                {
                                    Authorization: Cookies.get('Authorization')

                                }
                            }
                            defaultFileList={fileList}
                            onChange={this.uploadChange}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                    uploadButton
                                )}
                        </Upload>
                    </Form.Item>




                </Form>
            </MyDraggModal>
        )
    }
    uploadChange = (info) => {
        // this.isCanPostForm = false
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log({ info })
            const { file, fileList } = info
            var response = file.response
            console.log("response", response)

            if (response.code == 0) {
                this.setState({
                    fileList: response.data.files_path,
                    cover_image: response.data.files_path[0]
                })


                // this.isCanPostForm = true
            } else {
                fileList[0].status = "error"
                this.setState({
                    fileList: [],
                    cover_image: ''
                })



                message.error(response.message)

            }
        }else{
            console.log(info)
            if(info.fileList.length==0){
                this.setState({
                    fileList: [],
                    cover_image: ''
                })
            }
            
        }
    }

}