import CryptoJS from 'crypto-js';

// 加密
function encryptData(data) {
  const key = "dIUFigpSeQMuTz0c"; // 替换为与PHP方法相同的密钥

  const dataString = JSON.stringify(data);
  const iv = CryptoJS.lib.WordArray.random(16);

  const encryptedData = CryptoJS.AES.encrypt(
    dataString,
    CryptoJS.enc.Utf8.parse(key),
    { iv: iv, padding: CryptoJS.pad.Pkcs7 }
  ).toString();

  const combinedData = iv.concat(CryptoJS.enc.Base64.parse(encryptedData)).toString(CryptoJS.enc.Base64);

  return combinedData;
}

// 解密数据
const decryptData = (encryptedData) => {
    const key = "dIUFigpSeQMuTz0c";
    const decodedData = CryptoJS.enc.Base64.parse(encryptedData);
    const iv = decodedData.words.slice(0, 4); // 取前4个字节作为 IV
    const ciphertext = decodedData.words.slice(4); // 从第5个字节开始作为密文
  
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: CryptoJS.lib.WordArray.create(ciphertext)
      },
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: CryptoJS.lib.WordArray.create(iv),
        padding: CryptoJS.pad.Pkcs7
      }
    ).toString(CryptoJS.enc.Utf8);
  
    return JSON.parse(decrypted);
  };

// // 要加密的数据
// const postData = { "name": "王大锤" };

// // 加密数据
// const encryptedData = encryptData(postData);

// // 输出加密后的数据
// console.log('测试-加密后的数据:', encryptedData);

// // 解密数据
// const decryptedData = decryptData(encryptedData);

// // 输出解密后的数据
// console.log('测试-解密后的数据:', decryptedData);

export {
  encryptData,
  decryptData
};
