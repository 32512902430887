import React, { Component } from 'react'
import { Button, Space, Table, Tag, Form, DatePicker, Input, Row, Select, Image, Modal, message } from 'antd';
import SearchView from '../../../compontent/serchview';
import { Link } from 'react-router-dom';
import { NetGet, NetDelete } from '../../../axios_tools';
import { APIS, HOST } from '../../../config/api';
import RecruitmentFormModal from './recruitmentFormModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import global from '../../../common/utils/global';
import dayjs from 'dayjs';
// import AddYongHuModal from './addModal';
const { RangePicker } = DatePicker;
const { Option } = Select
export default class Recruitment extends Component {
    state = {
        // income:''
        tabLoading: true,
        tabData: []
    }
    defaultPageConfig = {
        current: 1,
        pageSize: 10,
        total: 0,
    }

    pageConfig = { ...this.defaultPageConfig }



    componentDidMount() {
        this.getTableDataList()
    }


    getTableDataList = (e) => {


        var searchPostData = e
        if (searchPostData && searchPostData.rangedate && searchPostData.rangedate.length) {
            const rangedate = e.rangedate
            searchPostData.start_time = dayjs(rangedate[0]).format("YYYY-MM-DD HH:mm")
            searchPostData.end_time = dayjs(rangedate[1]).format("YYYY-MM-DD HH:mm")
            delete searchPostData.rangedate
        }



        const { pageConfig } = this
        var postConfig = {
            page: pageConfig.current,
            page_size: pageConfig.pageSize,
            ...searchPostData
        }




        this.setState({
            tabLoading: true
        })
        NetGet(APIS.recruitment.list, postConfig)
            .then((res) => {
                if (res.code == 0) {


                    this.pageConfig = {
                        ...pageConfig,
                        total: res.data.total
                    }
                    this.setState({
                        tabLoading: false,
                        tabData: res.data.list
                    })
                    return
                }
            })
    }

    onSearch = (e) => {
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList(e)
    }


    showFormModal = (data) => {
        this.RecruitmentFormModal.showModal(data)
    }

    showDeleteModal = (item) => {
        const { confirm } = Modal;
        console.log(item)
        confirm({
            title: <div>确定删除<span style={{ color: "#6262ef", wordBreak: "break-all" }}>"{item.job_name}"</span>的招聘信息吗?</div>,
            icon: <ExclamationCircleOutlined />,
            //   content: '退出登录后需重新登录',
            okText: '确定',
            //   centered:"center",
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                NetDelete(APIS.recruitment.delete, { ids: [item.id] })
                    .then((e) => {
                        if (e.code == 0) {
                            message.success("移除成功")
                            this.setTabNowPageRefresh()
                        } else {
                            message.error(e.msg)
                        }
                    })
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }


    render() {
        const { tabLoading, tabData } = this.state
        const { pageConfig } = this
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 80,
            },
            {
                title: '岗位名称',
                dataIndex: 'job_name',
                width: 130,
            },

            {
                title: '工作性质',
                dataIndex: 'nature_of_work_str',
                key: "nature_of_work_str",
                align: "center",
                width: 100,
            },

            {
                title: '状态',
                dataIndex: 'status_str',
                key: "status_str",
                align: "center",
                width: 100,
                render: (text, _item) => <Tag color={_item.status == 1 ? "#87d068" : "#f50"}>{text}</Tag>,
            },


            {
                title: '招聘人数',
                dataIndex: 'recruiting_number',
                width: 100,
            },
            {
                title: '发布时间',
                dataIndex: 'release_time',
                width: 170,
            },
            {
                title: '工作地点',
                dataIndex: 'job_locations',
                width: 300,
                render: (text) => (
                    <div
                        className="line-clamp-ellipsis-3"
                        title={text}
                    >
                        {text}
                    </div>
                )
            },

            {
                title: '工作职责',
                dataIndex: 'job_responsibilities',
                width: 300,
                render: (text) => (
                    <div
                        className="line-clamp-ellipsis-3"
                        title={text}
                    >
                        {text}
                    </div>
                )
            },

            {
                title: '任职资格',
                dataIndex: 'job_qualifications',
                width: 300,
                render: (text) => (
                    <div
                        className="line-clamp-ellipsis-3"
                        title={text}
                    >
                        {text}
                    </div>
                )
            },

            {
                title: '联系方式',
                dataIndex: 'nature_of_work',
                width: 130,
            },
            {
                title: '公司邮箱',
                dataIndex: 'company_email',
                width: 200,
            },





            {
                title: '公司地址',
                dataIndex: 'company_address',
                width: 300,
                render: (text) => (
                    <div
                        className="line-clamp-ellipsis-3"
                        title={text}
                    >
                        {text}
                    </div>
                )
            },






            {
                title: '创建时间',
                dataIndex: 'created_at',
                width: 170,
            },

            {
                title: '更新时间',
                dataIndex: 'updated_at',
                width: 170,
            },

            {
                title: '排序',
                dataIndex: 'sort',
                width: 100,
            },


            {
                title: '操作',
                key: 'action',
                align: 'center',
                width: 180,
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="primary" size="small"
                            onClick={() => { this.showFormModal(record) }}
                        >编辑</Button>
                        <Button type="primary" size="small" danger
                            onClick={() => {
                                this.showDeleteModal(record)
                            }}
                        >删除</Button>
                    </Space>
                ),
            },
        ];

        return (
            <div className="pages-configs-bookmark">

                <SearchView
                    otherButton={
                        <Link to=''
                            style={{ marginLeft: 15 }}
                        >
                            <Button
                                type="primary"
                                onClick={() => { this.showFormModal() }}
                            >新增</Button></Link>
                    }
                    onSearch={this.onSearch}
                >
                    <Row>
                        <Form.Item
                            label="时间"
                            rules={[
                                {
                                    required: false,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const rangedateValue = getFieldValue('rangedate');
                                        if ((!value && !rangedateValue) || (value && rangedateValue)) {
                                            return Promise.resolve();
                                        }
                                        if (!value)
                                            return Promise.reject(new Error('时间类型和时间都必须选择!'));
                                    },
                                }),
                            ]}
                            style={{ marginRight: 2 }} name="time_type">
                            <Select
                                allowClear
                                placeholder='请选择类型'
                                style={{
                                    width: 120
                                }}
                            >
                                <Select.Option value={1}>发布时间</Select.Option>
                                <Select.Option value={2}>创建时间</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="rangedate"
                            label=""
                            style={{}}
                            rules={[
                                {
                                    required: false,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const time_type_value = getFieldValue('time_type');
                                        if ((!value && !time_type_value) || (value && time_type_value)) {
                                            return Promise.resolve();
                                        }
                                        if (!value)
                                            return Promise.reject(new Error('时间类型和时间都必须选择!'));
                                    },
                                }),
                            ]}
                        >
                            <RangePicker
                                showTime={{
                                    format: 'HH:mm',
                                }}
                                format="YYYY-MM-DD HH:mm"
                                value={''}
                            // disabledDate={disabledDate}
                            // onCalendarChange={val => setDates(val)}
                            // onChange={val => setValue(val)}
                            // onOpenChange={onOpenChange}
                            />
                        </Form.Item>
                    </Row>


                    <Row>
                        <Form.Item label="岗位名称" name="job_name">
                            <Input placeholder="请输入岗位名称" style={{ width: "160px" }} />
                        </Form.Item>

                       

                        <Form.Item
                            label="状态"
                            style={{}} name="status">
                            <Select
                                allowClear
                                placeholder='状态'
                                style={{
                                    width: 80
                                }}
                            >
                                <Select.Option value={1}>显示</Select.Option>
                                <Select.Option value={2}>隐藏</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="工作性质"
                            style={{}} name="nature_of_work">
                            <Select
                                allowClear
                                placeholder='工作性质'
                                style={{
                                    width: 150
                                }}
                            >
                                <Select.Option value={1}>全职</Select.Option>
                                <Select.Option value={2}>兼职</Select.Option>
                            </Select>
                        </Form.Item>
                    </Row>

                    {/* <Form.Item
                        name="rangedate"
                        label="创建时间"
                        style={{ marginLeft: 50 }}
                    >
                        <RangePicker
                            value={''}
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => setDates(val)}
                        // onChange={val => setValue(val)}
                        // onOpenChange={onOpenChange}
                        />
                    </Form.Item> */}

                </SearchView>


                <Table columns={columns} dataSource={tabData}
                    loading={tabLoading}
                    bordered
                    scroll={{ x: 1500 }}
                    pagination={{
                        ...pageConfig,
                        onChange: this.paginationChange
                    }}
                    rowClassName={(record, index) => index % 2 === 0 ? 'ant-table-even-row' : 'ant-table-odd-row'} // 根据奇偶行设置类名

                />

                <RecruitmentFormModal ref={e => this.RecruitmentFormModal = e}
                    setTabNowPageRefresh={this.setTabNowPageRefresh}
                    getDataOnStart={this.getDataOnStart}
                />
            </div>
        )
    }

    setTabNowPageRefresh = () => {
        this.getTableDataList()
    }

    getDataOnStart = () => {
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList()
    }
    paginationChange = (e) => {
        console.log("切换", e)
        const { pageConfig } = this
        this.pageConfig = {
            ...pageConfig,
            current: e
        }
        this.getTableDataList()

    }
}
