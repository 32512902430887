import React, { Component } from 'react'
import { Button, Space, Table, Tag, Form, DatePicker, Input, Image, Modal, message } from 'antd';
import SearchView from '../../../compontent/serchview';
import { Link } from 'react-router-dom';
import { NetGet, NetDelete } from '../../../axios_tools';
import { APIS, HOST } from '../../../config/api';
import LeadTeamFormModal from './leadTeamFormModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import global from '../../../common/utils/global';
// import AddYongHuModal from './addModal';
const { RangePicker } = DatePicker;

export default class LeadTeam extends Component {
    state = {
        // income:''
        tabLoading: true,
        tabData: []
    }
    defaultPageConfig = {
        current: 1,
        pageSize: 10,
        total: 0,
    }

    pageConfig = {...this.defaultPageConfig}



    componentDidMount() {
        this.getTableDataList()
    }


    getTableDataList = (searchData) => {
        const { pageConfig } = this
        var postConfig = {
            page: pageConfig.current,
            page_size: pageConfig.pageSize,
            ...searchData
        }
        this.setState({
            tabLoading: true
        })
        NetGet(APIS.leadteam.list, postConfig)
            .then((res) => {
                if (res.code == 0) {


                    this.pageConfig = {
                        ...pageConfig,
                        total: res.data.total
                    }
                    this.setState({
                        tabLoading: false,
                        tabData: res.data.list
                    })
                    return
                }
            })
    }

    onSearch = (e) => {
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList(e)
    }


    showFormModal=(data)=>{
        this.LeadTeamFormModal.showModal(data)
    }

    showDeleteModal = (item) => {
        const { confirm } = Modal;
        console.log(item)
        confirm({
            title: <div>确定移除成员<span style={{ color: "#6262ef", wordBreak: "break-all" }}>"{item.name}"</span>吗?</div>,
            icon: <ExclamationCircleOutlined />,
            //   content: '退出登录后需重新登录',
            okText: '确定',
            //   centered:"center",
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                NetDelete(APIS.leadteam.delete, { ids: [item.id] })
                    .then((e) => {
                        if (e.code == 0) {
                            message.success("移除成功")
                            this.setTabNowPageRefresh()
                        } else {
                            message.error(e.msg)
                        }
                    })
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }


    render() {
        const { tabLoading, tabData } = this.state
        const { pageConfig } = this
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: '姓名',
                dataIndex: 'name'
            },
            {
                title: '头像',
                dataIndex: 'avatar',
                align:"center",
                render: (text) =>  text?<Image
                width={45}
                // height={45}
                style={{
                    objectFit:'cover',
                    maxHeight:80
                }}
                onError={(e)=>{
                    try {
                        const target=e.target
                        const nextSiblingView =target.nextSibling 
                        nextSiblingView.style.display='none'
                        target.setAttribute("title","封面加载失败") 
                    } catch (error) {
                        
                    }
                    
                    // ant-image-mask
                }}
                fallback={global.configs.imageFallBack}
                // src={"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"}
                src={text}
              />
              :
              '/'
            },
            {
                title: '职位',
                dataIndex: 'position'
            },
            
          
            {
                title: '排序',
                dataIndex: 'sort'
            },



            {
                title: '操作',
                key: 'action',
                align:'center',
                width:280,
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="primary" size="small"
                        onClick={()=>{this.showFormModal(record)}}
                        >编辑</Button>
                        <Button type="primary" size="small" danger
                        onClick={()=>{
                            this.showDeleteModal(record)
                        }}
                        >移除</Button>
                    </Space>
                ),
            },
        ];

        return (
            <div className="pages-configs-bookmark">

                <SearchView
                    otherButton={
                        <Link to=''
                        style={{marginLeft:15}}
                        >
                        <Button
                            type="primary"
                        onClick={()=>{this.showFormModal()}}
                        >新增</Button></Link>
                    }
                    onSearch={this.onSearch}
                >
                    <Form.Item label="姓名" style={{ width: "250px" }} name="name">
                        <Input placeholder="请输入姓名" />
                    </Form.Item>

                    {/* <Form.Item
                        name="rangedate"
                        label="创建时间"
                        style={{ marginLeft: 50 }}
                    >
                        <RangePicker
                            value={''}
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => setDates(val)}
                        // onChange={val => setValue(val)}
                        // onOpenChange={onOpenChange}
                        />
                    </Form.Item> */}

                </SearchView>


                <Table columns={columns} dataSource={tabData}
                    loading={tabLoading}
                    bordered
                    pagination={{
                        ...pageConfig,
                        onChange: this.paginationChange
                    }} 
                    rowClassName={(record, index) => index % 2 === 0 ? 'ant-table-even-row' : 'ant-table-odd-row'} // 根据奇偶行设置类名

                    />

                    <LeadTeamFormModal ref={e=>this.LeadTeamFormModal=e}
                    setTabNowPageRefresh={this.setTabNowPageRefresh}
                    getDataOnStart = {this.getDataOnStart}
                    />
            </div>
        )
    }

    setTabNowPageRefresh=()=>{
        this.getTableDataList()
    }

    getDataOnStart=()=>{
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList()
    }
    paginationChange = (e) => {
        console.log("切换", e)
        const { pageConfig } = this
        this.pageConfig = {
            ...pageConfig,
            current: e
        }
        this.getTableDataList()

    }
}
