import { APIS } from "../config/api"
import { NetGet } from "../axios_tools"
import { message } from "antd"

// actions.js
// action也是函数
export function setPageTitle(data) {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_PAGE_TITLE', data: data })
  }
}





export function setUserInfo(isLoginOut) {
  return (dispatch, getState) => {
    if(isLoginOut){
      dispatch({ type: 'user_info', data: '' })
    }else{
      NetGet(APIS.adminAccount.userinfo)
      .then((res) => {
        let { code, } = res
        if (code === 0) {
          dispatch({ type: 'user_info', data: res.data?res.data[0]:"" })
        }else{
          message.error("账号权限获取失败，请刷新页面")
        }
      })
      .catch(()=>{
        message.error("账号权限获取失败，请刷新页面")
      })
    }
    
  }
}

export function setInfoList(data) {
  return (dispatch, getState) => {
    // 使用fetch实现异步请求
    fetch('/api/getInfoList', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      return res.json()
    }).then(data => {
      let { code, } = data
      if (code === 0) {
        dispatch({ type: 'SET_INFO_LIST', data: data.data })
      }
    })
  }
}