import React from 'react';
import { Select, Form, Input, message, Checkbox, DatePicker, Row, Col, Upload, InputNumber } from 'antd'
import './index.css'
import MyDraggModal from '../../../../compontent/myDraggModal';
import { APIS } from '../../../../config/api';
import Cookies from 'js-cookie';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { NetPost, NetPatch } from '../../../../axios_tools';
import dayjs from 'dayjs';
const { Option } = Select;
const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e ?.fileList;
};
export default class ResumedeliveryFormModal extends React.Component {
    state = {
        fileList: [],
        cover_image: '',
        imageUrl: '',
        loading: false
    }
    formRef = React.createRef()




    showModal = (initialValues) => {
        this.MyDraggModal.showModal(() => {

            if (initialValues) {
                this.formRef.current.resetFields();

                console.log(initialValues)
                this.initialValues = { ...initialValues }

                if(this.initialValues.release_time){
                    this.initialValues.release_time=dayjs(this.initialValues.release_time)
                }

                this.formRef.current.setFieldsValue(this.initialValues)
                this.setState({
                    isEdit: true
                })
            } else {
                this.formRef.current.resetFields();

                this.setState({
                    isEdit: false
                })
            }
        })

        console.log("initialValues", initialValues)


    }


    setConfirmLoading = (e) => {
        this.MyDraggModal && this.MyDraggModal.setConfirmLoading(e)
    }







    handleOk = () => {

        this.formRef.current
            .validateFields()
            .then((values) => {

                var data = values
                console.log(data)
                this.setConfirmLoading(true)
                data.isEdit = this.state.isEdit
                if (data.isEdit)
                    data.id = this.initialValues.id

                if (!data.sort) {
                    data.sort = 0
                }
                if (data.release_time) {
                    data.release_time = dayjs(data.release_time).format("YYYY-MM-DD HH:mm")

                }

                var NetObj = data.isEdit ? NetPatch : NetPost
                var NetUrl = data.isEdit ? APIS.recruitment.edit : APIS.recruitment.add
                NetObj(NetUrl, data)
                    .then((res) => {
                        if (res.code == 0) {
                            if (data.isEdit) {
                                this.props.setTabNowPageRefresh()
                            } else {
                                this.props.getDataOnStart()
                            }
                            this.MyDraggModal && this.MyDraggModal.handleCancel()
                            message.success(data.isEdit ? "修改成功" : "添加成功")
                        } else {
                            message.error(res.message)
                        }
                        this.setConfirmLoading(false)
                    })
                    .catch(() => {
                        this.setConfirmLoading(false)
                    })


            })
    }



    render() {
        const layout = {
            labelCol: { span: 0 },
            wrapperCol: { span: 16 },
        };
        const { userdata } = this.props
        const { isEdit, fileList, imageUrl, loading } = this.state
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div
                    style={{
                        marginTop: 8,
                    }}
                >
                    点击上传
                  </div>
            </div>
        );
        console.log({ isEdit })
        return (
            <MyDraggModal
                bodyStyle={{ overflowX: "auto" }}
                
                contentStyle={{minWidth:830}}
                title={(isEdit ? "编辑" : "添加") + "招聘"}
                width={'auto'}
                handleOk={() => this.handleOk()}
                handleCancel={() => {
                    // this.formRef.current.resetFields()
                    this.setState({
                        fileList: [],
                        cover_image: '',
                        imageUrl: '',
                        loading: false
                    })

                }}
                ref={e => this.MyDraggModal = e}>
                <Form
                    {...layout}
                    ref={this.formRef}
                    className="guize_peizhi_modal"
                    style={{
                        marginTop: "30px"
                    }}
                    initialValues={{
                        'status': 1
                    }}
                >

                    <Row justify="center" gutter={ { xs: 8, sm: 16, md: 24}}>


                        <Col  justify={'center'}>
                            <Form.Item
                                label="岗位名称"
                                name="job_name"
                                rules={[{ required: true, message: '必须填写岗位名称' },]}

                            >
                                <Input style={{ width: 300 }} placeholder={"请输入岗位名称"} />
                            </Form.Item>

                            <Form.Item
                                label="工作性质"
                                name="nature_of_work"
                                rules={[{ required: true, message: '必须选择工作性质' }]}
                            // {
                            //     pattern: /^[^\s]*$/,
                            //     message: '禁⽌输⼊空格',
                            //     }]}
                            >
                                <Select
                                    style={{
                                        width: 300
                                    }}
                                    placeholder={"请选择工作性质"}
                                >
                                    <Option value={1}>全职</Option>
                                    <Option value={2}>兼职</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="招聘人数"
                                name="recruiting_number"
                                rules={[{ required: true, message: '必须填写招聘人数' },]}

                            >
                                <InputNumber style={{ width: 300 }} placeholder={"请输入招聘人数"} />
                            </Form.Item>


                            <Form.Item
                                label="工作地点"
                                name="job_locations"
                                rules={[{ required: true, message: '必须填写工作地点' },]}
                            >
                                <Input.TextArea style={{ width: 300 }} placeholder={"请输入工作地点"}
                                    autoSize={{ minRows: 2, maxRows: 6 }}

                                />
                            </Form.Item>

                            <Form.Item
                                label="工作职责"
                                name="job_responsibilities"
                                rules={[{ required: true, message: '必须填写工作职责' },]}
                            >
                                <Input.TextArea style={{ width: 300 }} placeholder={"请输入工作职责"}
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                />
                            </Form.Item>


                            <Form.Item
                                label="任职资格"
                                name="job_qualifications"
                                rules={[{ required: true, message: '必须填写任职资格' },]}
                            >
                                <Input.TextArea style={{ width: 300 }} placeholder={"请输入任职资格"}
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                />
                            </Form.Item>
                        </Col>



                        <Col >
                            <Form.Item
                                label="联系方式"
                                name="contact_information"
                                rules={[{ required: true, message: '必须填写联系方式' },]}

                            >
                                <Input style={{ width: 300 }} placeholder={"请输入联系方式"} />
                            </Form.Item>

                            <Form.Item
                                label="公司邮箱"
                                name="company_email"
                                rules={[{ required: true, message: '必须填写公司邮箱' },]}

                            >
                                <Input style={{ width: 300 }} placeholder={"请输入公司邮箱"} />
                            </Form.Item>

                            <Form.Item
                                label="公司地址"
                                name="company_address"
                                rules={[{ required: true, message: '必须填写公司地址' },]}

                            >
                                <Input.TextArea style={{ width: 300 }} placeholder={"请输入公司地址"}
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                />
                            </Form.Item>


                            <Form.Item label="发布时间"
                                name='release_time'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择发布时间',
                                    },
                                ]}

                            >
                                <DatePicker
                                    showTime={{
                                        format: 'HH:mm',
                                    }}
                                    format="YYYY-MM-DD HH:mm"
                                    style={{ width: "300px" }} />
                            </Form.Item>


                            <Form.Item label="状态"
                                name='status'
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择状态',
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: "200px"
                                    }}
                                >
                                    <Option value={1}>显示</Option>
                                    <Option value={2}>隐藏</Option>
                                </Select>
                            </Form.Item>


                            <Form.Item
                                label="排序"
                                name="sort"
                            // {
                            //     pattern: /^[^\s]*$/,
                            //     message: '禁⽌输⼊空格',
                            //     }]}
                            >
                                <InputNumber style={{ width: 200 }} placeholder={"请输入排序数字"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </MyDraggModal>
        )
    }
    uploadChange = (info) => {
        // this.isCanPostForm = false
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log({ info })
            const { file, fileList } = info
            var response = file.response
            console.log("response", response)

            if (response.code == 0) {
                this.setState({
                    fileList: response.data.files_path,
                    cover_image: response.data.files_path[0]
                })


                // this.isCanPostForm = true
            } else {
                fileList[0].status = "error"
                this.setState({
                    fileList: [],
                    cover_image: ''
                })



                message.error(response.message)

            }
        } else {
            console.log(info)
            if (info.fileList.length == 0) {
                this.setState({
                    fileList: [],
                    cover_image: ''
                })
            }

        }
    }

}