import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import Routers from './routers';
import store from './store/index.js'
import history from './routers/history';
import zhCN from 'antd/lib/locale/zh_CN';
import {ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
if (process.env.NODE_ENV === 'production'&&window.location.host.indexOf('96ck')<0) {
  console.log = () => {};
}
dayjs.locale('zh-cn');
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>

    <HashRouter>
    <ConfigProvider locale={zhCN}>
<Routers history={history}/>

    </ConfigProvider>
    
    </HashRouter>
    </Provider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
