const HOST = process.env.NODE_ENV == 'development'?"":''//http://58.144.199.11:9501//http://192.168.0.188:9502
const PROTOCOL = '//'

const APIS = {
    default_config:"/index/config/lists",
    adminAccount:{
        login:'/admin/admin/login',//登录
        add:'/admin/admin/add',//新增
        captcha:'/admin/admin/captcha',//验证码
        edit:'/admin/admin/edit',
        delete:'/admin/admin/delete',
        batchModificationStatus:'/admin/admin/batchModificationStatus',//批量修改状态
        getBaseData:'/admin/admin/getBaseData',//获取基础数据
        userinfo:"/admin/admin/userinfo",//账号用户信息
        list:'/admin/admin/list',
    },
    article:{
        list:'/admin/article/list',//文章列表
        add:'/admin/article/add',
        edit:'/admin/article/edit',
        delete:'/admin/article/delete',
    },
    articleCategory:{
        list:'/admin/articleCategory/list',//文章分类分页列表
        allList:'/admin/articleCategory/allList',//文章分类所有列表
    },

    //招聘
    recruitment:{
        list:"/admin/recruitment/list",
        add:'/admin/recruitment/add',
        edit:'/admin/recruitment/edit',
        getBaseData:'/admin/recruitment/getBaseData',
        delete:'/admin/recruitment/delete'
    },
    //领导班子
    leadteam:{
        list:"/admin/leadershipTeam/list",
        add:'/admin/leadershipTeam/add',
        edit:'/admin/leadershipTeam/edit',
        delete:'/admin/leadershipTeam/delete'
    },
    //简历
    resumedelivery:{
        list:"/admin/resumeDeliveryRecord/list",
    },
    public:{
        uploadFiles:'/admin/common/uploadFile',
        up_file_default:"/admin/common/up_file_default"
    },
    role:{
        rolelist:"/admin/admin/role"
    },
    log:{
        loglist:"/admin/admin/log"
    }
}


export {
    HOST,
    APIS,
    PROTOCOL
}