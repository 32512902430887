import React, { Component } from 'react'
import { Button, Space, Table, Tag, Form, DatePicker, Input, Descriptions, Row, Select, Image, Modal, message } from 'antd';
import SearchView from '../../../compontent/serchview';
import { Link } from 'react-router-dom';
import { NetGet, NetDelete } from '../../../axios_tools';
import { APIS, HOST } from '../../../config/api';
import ResumedeliveryFormModal from './resumedeliveryFormModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import global from '../../../common/utils/global';
import dayjs from 'dayjs';
import MyDraggModal from '../../../compontent/myDraggModal';
// import AddYongHuModal from './addModal';
const { RangePicker } = DatePicker;
const { Option } = Select
export default class Resumedelivery extends Component {
    state = {
        // income:''
        tabLoading: true,
        tabData: [],
        recruitmentInfo: {}
    }
    defaultPageConfig = {
        current: 1,
        pageSize: 10,
        total: 0,
    }

    pageConfig = { ...this.defaultPageConfig }



    componentDidMount() {
        this.getTableDataList()
    }


    getTableDataList = (e) => {


        var searchPostData = e
        if (searchPostData && searchPostData.rangedate && searchPostData.rangedate.length) {
            const rangedate = e.rangedate
            searchPostData.start_time = dayjs(rangedate[0]).format("YYYY-MM-DD HH:mm")
            searchPostData.end_time = dayjs(rangedate[1]).format("YYYY-MM-DD HH:mm")
            delete searchPostData.rangedate
        }



        const { pageConfig } = this
        var postConfig = {
            page: pageConfig.current,
            page_size: pageConfig.pageSize,
            ...searchPostData
        }




        this.setState({
            tabLoading: true
        })
        NetGet(APIS.resumedelivery.list, postConfig)
            .then((res) => {
                if (res.code == 0) {


                    this.pageConfig = {
                        ...pageConfig,
                        total: res.data.total
                    }
                    this.setState({
                        tabLoading: false,
                        tabData: res.data.list
                    })
                    return
                } else {
                    message.error(res.message)
                    this.setState({
                        tabLoading: false
                    })
                }
            })
    }

    onSearch = (e) => {
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList(e)
    }


    showFormModal = (data) => {
        this.ResumedeliveryFormModal.showModal(data)
    }

    showDeleteModal = (item) => {
        const { confirm } = Modal;
        console.log(item)
        confirm({
            title: <div>确定删除<span style={{ color: "#6262ef", wordBreak: "break-all" }}>"{item.job_name}"</span>的招聘信息吗?</div>,
            icon: <ExclamationCircleOutlined />,
            //   content: '退出登录后需重新登录',
            okText: '确定',
            //   centered:"center",
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                NetDelete(APIS.recruitment.delete, { ids: [item.id] })
                    .then((e) => {
                        if (e.code == 0) {
                            message.success("移除成功")
                            this.setTabNowPageRefresh()
                        } else {
                            message.error(e.msg)
                        }
                    })
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }


    render() {
        const { tabLoading, tabData } = this.state
        const { pageConfig } = this
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 80,
            },
            {
                title: '姓名',
                dataIndex: 'name',
                width: 130,
            },

            {
                title: '性别',
                dataIndex: 'gender',
                key: "gender",
                align: "center",
                width: 100,
                render: (text, _item) => text == 1 ? "男" : "女",
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                key: "phone",
                align: "center",
                width: 200
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                width: 200,

            },
            {
                title: '简历',
                dataIndex: 'resume',
                width: 100,
                render: (text, _item) => {
                    if (text) {
                        return <a href={text} download={_item.name + "-简历-" + text.split("/").pop()} target='_blank'>下载简历</a>
                    } else {
                        return '/'
                    }
                },
            },
            {
                title: '证书',
                dataIndex: 'certificates',
                width: 100,
                render: (text, _item) => {
                    if (text) {
                        return <a href={text} download={_item.name + "-证书-" + text.split("/").pop()} target='_blank'>下载证书</a>
                    } else {
                        return '/'
                    }
                },
            },
            {
                title: '应聘岗位',
                dataIndex: 'recruitment',
                width: 170,
                align: 'center',
                render: (text, _) => {
                    //     let color = text.status_str > 5 ? 'geekblue' : 'green';
                    // if (tag === 'loser') {
                    //   color = 'volcano';
                    // }
                    if (!text) {
                        return ''
                    }
                    var color = 'geekblue'
                    return <Tag color={color}
                        className="line-clamp-ellipsis"
                        title={text.job_name}
                        style={{
                            maxWidth: "170px"
                        }}
                    >
                        {text.job_name}
                    </Tag>
                }
            },


            {
                title: '创建时间',
                dataIndex: 'created_at',
                width: 170,
            },

            {
                title: '更新时间',
                dataIndex: 'updated_at',
                width: 170,
            },

            {
                title: '招聘状态',
                dataIndex: 'recruitment',
                width: 100,
                align: 'center',
                render: (text, _) => {
                    return text ? text.status_str : ''
                    // return <Button
                    // onClick={()=>{
                    //     this.setState({
                    //         recruitmentInfo:{...text,resumed_username:_.name}
                    //     })
                    //     this.recruitmentInfoRef.showModal()
                    // }}
                    // >查看</Button>
                }
            },
            {
                title: '招聘id',
                dataIndex: 'recruitment_id',
                width: 80,
            },

            // {
            //     title: '操作',
            //     key: 'action',
            //     align: 'center',
            //     width: 180,
            //     fixed: 'right',
            //     render: (_, record) => (
            //         <Space size="middle">
            //             <Button type="primary" size="small"
            //                 onClick={() => { this.showFormModal(record) }}
            //             >编辑</Button>
            //             <Button type="primary" size="small" danger
            //                 onClick={() => {
            //                     this.showDeleteModal(record)
            //                 }}
            //             >删除</Button>
            //         </Space>
            //     ),
            // },
        ];
        const { recruitmentInfo } = this.state
        return (
            <div className="pages-configs-bookmark">

                <SearchView
                    // otherButton={
                    //     <Link to=''
                    //         style={{ marginLeft: 15 }}
                    //     >
                    //         <Button
                    //             type="primary"
                    //             onClick={() => { this.showFormModal() }}
                    //         >新增</Button></Link>
                    // }
                    onSearch={this.onSearch}
                >
                    <Row>
                        {/* <Form.Item
                            label="时间"
                            rules={[
                                {
                                    required: false,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const rangedateValue = getFieldValue('rangedate');
                                        if ((!value && !rangedateValue) || (value && rangedateValue)) {
                                            return Promise.resolve();
                                        }
                                        if (!value)
                                            return Promise.reject(new Error('时间类型和时间都必须选择!'));
                                    },
                                }),
                            ]}
                            style={{ marginRight: 2 }} name="time_type">
                            <Select
                                allowClear
                                placeholder='请选择类型'
                                style={{
                                    width: 120
                                }}
                            >
                                <Select.Option value={1}>发布时间</Select.Option>
                                <Select.Option value={2}>创建时间</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="rangedate"
                            label=""
                            style={{}}
                            rules={[
                                {
                                    required: false,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const time_type_value = getFieldValue('time_type');
                                        if ((!value && !time_type_value) || (value && time_type_value)) {
                                            return Promise.resolve();
                                        }
                                        if (!value)
                                            return Promise.reject(new Error('时间类型和时间都必须选择!'));
                                    },
                                }),
                            ]}
                        >
                            <RangePicker
                                showTime={{
                                    format: 'HH:mm',
                                }}
                                format="YYYY-MM-DD HH:mm"
                                value={''}
                            // disabledDate={disabledDate}
                            // onCalendarChange={val => setDates(val)}
                            // onChange={val => setValue(val)}
                            // onOpenChange={onOpenChange}
                            />
                        </Form.Item> */}



                    </Row>


                    <Row>
                        {/* <Form.Item label="岗位名称" name="job_name">
                            <Input placeholder="请输入岗位名称" style={{ width: "160px" }} />
                        </Form.Item> */}



                        <Form.Item label="姓名" name="name">
                            <Input placeholder="输入简历姓名" style={{ width: "160px" }} />
                        </Form.Item>

                        <Form.Item label="手机号" name="phone">
                            <Input placeholder="输入手机号" style={{ width: "160px" }} />
                        </Form.Item>
                        <Form.Item label="邮箱" name="email">
                            <Input placeholder="输入邮箱" style={{ width: "160px" }} />
                        </Form.Item>


                        <Form.Item
                            label="性别"
                            style={{}} name="gender">
                            <Select
                                allowClear
                                placeholder='性别'
                                style={{
                                    width: 80
                                }}
                            >
                                <Select.Option value={1}>男</Select.Option>
                                <Select.Option value={2}>女</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item label="招聘ID" name="recruitment_id">
                            <Input placeholder="输入招聘ID" style={{ width: "160px" }} />
                        </Form.Item>

                        {/* <Form.Item
                            label="状态"
                            style={{}} name="status">
                            <Select
                                allowClear
                                placeholder='状态'
                                style={{
                                    width: 80
                                }}
                            >
                                <Select.Option value={1}>显示</Select.Option>
                                <Select.Option value={2}>隐藏</Select.Option>
                            </Select>
                        </Form.Item> */}
                        {/* <Form.Item
                            label="工作性质"
                            style={{}} name="nature_of_work">
                            <Select
                                allowClear
                                placeholder='工作性质'
                                style={{
                                    width: 150
                                }}
                            >
                                <Select.Option value={1}>全职</Select.Option>
                                <Select.Option value={2}>兼职</Select.Option>
                            </Select>
                        </Form.Item> */}
                    </Row>

                    {/* <Form.Item
                        name="rangedate"
                        label="创建时间"
                        style={{ marginLeft: 50 }}
                    >
                        <RangePicker
                            value={''}
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => setDates(val)}
                        // onChange={val => setValue(val)}
                        // onOpenChange={onOpenChange}
                        />
                    </Form.Item> */}

                </SearchView>


                <Table columns={columns} dataSource={tabData}
                    loading={tabLoading}
                    bordered
                    scroll={{ x: 1500 }}
                    pagination={{
                        ...pageConfig,
                        onChange: this.paginationChange
                    }}
                    rowClassName={(record, index) => index % 2 === 0 ? 'ant-table-even-row' : 'ant-table-odd-row'} // 根据奇偶行设置类名

                />

                <ResumedeliveryFormModal ref={e => this.ResumedeliveryFormModal = e}
                    setTabNowPageRefresh={this.setTabNowPageRefresh}
                    getDataOnStart={this.getDataOnStart}
                />


                <MyDraggModal
                    maskClosable={true}
                    footer={null}
                    ref={e => this.recruitmentInfoRef = e}
                >
                    <Descriptions
                        bordered
                        title={"简历-" + recruitmentInfo.resumed_username + "-招聘信息"}
                        size={'default'}
                    // extra={<Button type="primary">Edit</Button>}
                    >
                        <Descriptions.Item label="招聘ID">{recruitmentInfo.id}</Descriptions.Item>
                        <Descriptions.Item label="岗位名称">{recruitmentInfo.job_name}</Descriptions.Item>
                        <Descriptions.Item label="状态">{recruitmentInfo.status_str}</Descriptions.Item>
                        {/* <Descriptions.Item label="Config Info">
          Data disk type: MongoDB
          <br />
          Database version: 3.4
          <br />
          Package: dds.mongo.mid
          <br />
          Storage space: 10 GB
          <br />
          Replication factor: 3
          <br />
          Region: East China 1
          <br />
        </Descriptions.Item> */}
                    </Descriptions>
                </MyDraggModal>
            </div>
        )
    }

    setTabNowPageRefresh = () => {
        this.getTableDataList()
    }

    getDataOnStart = () => {
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList()
    }
    paginationChange = (e) => {
        console.log("切换", e)
        const { pageConfig } = this
        this.pageConfig = {
            ...pageConfig,
            current: e
        }
        this.getTableDataList()

    }
}
