import React, { Component } from 'react'
import { Button, Space, Table, Tag, Form, DatePicker, Modal, Image, message } from 'antd';
import SearchView from '../../../compontent/serchview';
import { Link } from 'react-router-dom';
import { NetGet, NetDelete } from '../../../axios_tools';
import { APIS, HOST } from '../../../config/api';
import global from '../../../common/utils/global';
import AdmAccFormModal from './admAccFormModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import AddYongHuModal from './addModal';
const { RangePicker } = DatePicker;

export default class UserList extends Component {
    state = {
        // income:''
        tabLoading: true,
        tabData: [],
        rolelist:[]
    }

    defaultPageConfig={
        current: 1,
        pageSize: 10,
        total: 0,
    }
    pageConfig = {...this.defaultPageConfig}



    componentDidMount() {
        this.getTableDataList()
        this.getRoleList()
    }

    getRoleList=()=>{
        NetGet(APIS.role.rolelist)
        .then((res)=>{
            if(res.code==0){
                this.setState({
                    rolelist:res.data
                })
            }
        })
    }


    getTableDataList = () => {
        const { pageConfig } = this
        var postConfig = {
            page: pageConfig.current,
            page_size: pageConfig.pageSize
        }
        this.setState({
            tabLoading: true
        })
        NetGet(APIS.adminAccount.list, postConfig)
            .then((res) => {
                if (res.code == 0) {


                    this.pageConfig = {
                        ...pageConfig,
                        total: res.data.total
                    }
                    this.setState({
                        tabLoading: false,
                        tabData: res.data.list
                    })
                    return
                }
            })
    }

    onSearch = () => {
        alert("搜索")
    }

    //删除
     showDeleteModal = (item) => {
        const { confirm } = Modal;
        console.log(item)
        confirm({
            title: <div>确定删除账号<span style={{ color: "#6262ef", wordBreak: "break-all" }}>"{item.user_name}"</span>吗?</div>,
            icon: <ExclamationCircleOutlined />,
            //   content: '退出登录后需重新登录',
            okText: '确定',
            //   centered:"center",
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                NetDelete(APIS.adminAccount.delete, { ids: [item.id] })
                    .then((e) => {
                        if (e.code == 0) {
                            message.success("删除成功")
                            this.getTableDataList()
                        } else {
                            message.error(e.msg)
                        }
                    })
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }

    render() {
        const { tabLoading, tabData } = this.state
        const { pageConfig } = this
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            
            {
                title: '用户名',
                dataIndex: 'user_name'
            },
            {
                title: '昵称',
                dataIndex: 'nick_name',
            },
            {
                title:"权限",
                dataIndex:"role_name",
            },
            {
                title: '头像',
                dataIndex: 'avatar',
                // align:"center",
                render: (text) =>  text?<Image
                width={45}
                width={45}
                style={{
                    objectFit:"cover"
                }}
                onError={(e)=>{
                    try {
                        const target=e.target
                        const nextSiblingView =target.nextSibling 
                        nextSiblingView.style.display='none'
                        target.setAttribute("title","封面加载失败") 
                    } catch (error) {
                        
                    }
                    
                    // ant-image-mask
                }}
                fallback={global.configs.imageFallBack}
                // src={"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"}
                src={text}
              />
              :
              '/'
            },
            {
                title: '电子邮箱',
                dataIndex: 'email'
            },

            {
                title: '手机号码',
                dataIndex: 'mobile'
            },
            {
                title: '最后一次登录时间',
                dataIndex: 'last_login_time'
            },
            {
                title: '最后一次登录ip',
                dataIndex: 'last_login_ip'
            },

            {
                title: '状态',
                dataIndex: 'status',
                key: "status",
                render: (e,_item) => <Tag color={e==1?"green":"volcano"}>{_item.status_str}</Tag>,
            },
            
         
           



            {
                title: '操作',
                key: 'action',
                render: (_, record) => (
                    <Space size="middle">
                        <Button type="primary" size="small"
                        onClick={()=>{this.AdmAccFormModal.showModal(record)}}
                        
                        >编辑</Button>
                        <Button type="primary" size="small" danger
                        onClick={()=>{
                            this.showDeleteModal(record)
                        }}
                        >删除</Button>
                    </Space>
                ),
            },
        ];

        return (
            <div className="pages-configs-bookmark">

                <SearchView
                    otherButton={
                        <Link to=''
                        style={{marginLeft:15}}
                        >
                        <Button
                            type="primary"
                        onClick={()=>{
                            this.AdmAccFormModal.showModal()
                        }}
                        >新增账号</Button></Link>
                    }
                    // onSearch={this.onSearch}
                >
                    {/* <Form.Item label="" style={{ width: "250px" }} name="title">
                        <Input placeholder="请输入账号" />
                    </Form.Item>

                    <Form.Item
                        name="rangedate"
                        label="创建时间"
                        style={{ marginLeft: 50 }}
                    >
                        <RangePicker
                            value={''}
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => setDates(val)}
                        // onChange={val => setValue(val)}
                        // onOpenChange={onOpenChange}
                        />
                    </Form.Item> */}

                </SearchView>


                <Table columns={columns} dataSource={tabData}
                    loading={tabLoading}
                    pagination={{
                        ...pageConfig,
                        onChange: this.paginationChange
                    }} />
                    <AdmAccFormModal ref={e=>this.AdmAccFormModal=e}
                    setTabNowPageRefresh={this.setTabNowPageRefresh}
                    getDataOnStart = {this.getDataOnStart}
                    rolelist={this.state.rolelist}
                    />
            </div>
        )
    }
    setTabNowPageRefresh=()=>{
        this.getTableDataList()
    }

    getDataOnStart=()=>{
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList()
    }

    paginationChange = (e) => {
        console.log("切换", e)
        const { pageConfig } = this
        this.pageConfig = {
            ...pageConfig,
            current: e
        }
        this.getTableDataList()

    }
}
