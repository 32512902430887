import React, { Component } from 'react'
import { Button, Space, Table, Tag, Form, DatePicker, Input, Descriptions, Row, Select, Image, Modal, message, Col } from 'antd';
import SearchView from '../../../compontent/serchview';
import { Link } from 'react-router-dom';
import { NetGet, NetDelete } from '../../../axios_tools';
import { APIS, HOST } from '../../../config/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import global from '../../../common/utils/global';
import dayjs from 'dayjs';
import MyDraggModal from '../../../compontent/myDraggModal';
// import AddYongHuModal from './addModal';
const { RangePicker } = DatePicker;
const { Option } = Select
export default class Logs extends Component {
    state = {
        // income:''
        tabLoading: true,
        tabData: [],
        recruitmentInfo: {}
    }
    defaultPageConfig = {
        current: 1,
        pageSize: 10,
        total: 0,
    }

    pageConfig = { ...this.defaultPageConfig }



    componentDidMount() {
        this.getTableDataList()
    }


    getTableDataList = (e) => {


        var searchPostData = e
        if (searchPostData && searchPostData.rangedate && searchPostData.rangedate.length) {
            const rangedate = e.rangedate
            searchPostData.start_time = dayjs(rangedate[0]).format("YYYY-MM-DD HH:mm")
            searchPostData.end_time = dayjs(rangedate[1]).format("YYYY-MM-DD HH:mm")
            delete searchPostData.rangedate
        }



        const { pageConfig } = this
        var postConfig = {
            page: pageConfig.current,
            page_size: pageConfig.pageSize,
            ...searchPostData
        }




        this.setState({
            tabLoading: true
        })
        NetGet(APIS.log.loglist, postConfig)
            .then((res) => {
                if (res.code == 0) {


                    this.pageConfig = {
                        ...pageConfig,
                        total: res.data.total
                    }
                    this.setState({
                        tabLoading: false,
                        tabData: res.data.list
                    })
                    return
                } else {
                    message.error(res.message)
                    this.setState({
                        tabLoading: false
                    })
                }
            })
    }

    onSearch = (e) => {
        this.pageConfig = this.defaultPageConfig

        const value = { ...e }
        if (value.time) {
            value.time = dayjs(value.time).format("YYYY-MM-DD")
        }
        Object.keys(value).map((item) => {
            if (!value[item]) {
                delete value[item]
            }else{
                value[item]=value[item].trim()
            }
        })
        console.log("value---------", value)
        this.getTableDataList(value)
    }



    showDeleteModal = (item) => {
        const { confirm } = Modal;
        console.log(item)
        confirm({
            title: <div>确定删除<span style={{ color: "#6262ef", wordBreak: "break-all" }}>"{item.job_name}"</span>的招聘信息吗?</div>,
            icon: <ExclamationCircleOutlined />,
            //   content: '退出登录后需重新登录',
            okText: '确定',
            //   centered:"center",
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                NetDelete(APIS.recruitment.delete, { ids: [item.id] })
                    .then((e) => {
                        if (e.code == 0) {
                            message.success("移除成功")
                            this.setTabNowPageRefresh()
                        } else {
                            message.error(e.msg)
                        }
                    })
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }


    render() {
        const { tabLoading, tabData } = this.state
        const { pageConfig } = this
        const columns = [
            {
                title: '用户id',
                dataIndex: 'user_id',
                width: 80,
            },
            {
                title: '用户名',
                dataIndex: 'user_name',
                width: 100,
            },

            {
                title: 'ip',
                dataIndex: 'ip',
                key: "ip",
                align: "center",
                width: 200,
            },
            {
                title: '描述',
                dataIndex: 'msg',
                width: 300,
            },
            {
                title: 'url',
                dataIndex: 'url',
                key: "url",
                align: "center",
            },

            {
                title: '时间',
                dataIndex: 'time',
                width: 200,

            },

            // {
            //     title: '操作',
            //     key: 'action',
            //     align: 'center',
            //     width: 180,
            //     fixed: 'right',
            //     render: (_, record) => (
            //         <Space size="middle">
            //             <Button type="primary" size="small"
            //                 onClick={() => { this.showFormModal(record) }}
            //             >编辑</Button>
            //             <Button type="primary" size="small" danger
            //                 onClick={() => {
            //                     this.showDeleteModal(record)
            //                 }}
            //             >删除</Button>
            //         </Space>
            //     ),
            // },
        ];
        const { recruitmentInfo } = this.state
        return (
            <div className="pages-configs-bookmark">

                <SearchView
                    // otherButton={
                    //     <Link to=''
                    //         style={{ marginLeft: 15 }}
                    //     >
                    //         <Button
                    //             type="primary"
                    //             onClick={() => { this.showFormModal() }}
                    //         >新增</Button></Link>
                    // }
                    onSearch={this.onSearch}
                >


                    <Row>
                        {/* <Form.Item label="岗位名称" name="job_name">
                            <Input placeholder="请输入岗位名称" style={{ width: "160px" }} />
                        </Form.Item> */}



                        <Form.Item label="用户名" name="user_name">
                            <Input placeholder="输入用户名" style={{ width: "160px" }}
                                allowClear
                            />
                        </Form.Item>


                        <Form.Item label="日期" name="time">
                            <DatePicker
                                placeholder="选择日期"
                                style={{ width: "160px" }}
                            />
                        </Form.Item>

                        <Form.Item label="ip" name="ip">
                            <Input placeholder="输入ip地址" style={{ width: "160px" }} allowClear />
                        </Form.Item>




                        {/* <Form.Item
                            label="状态"
                            style={{}} name="status">
                            <Select
                                allowClear
                                placeholder='状态'
                                style={{
                                    width: 80
                                }}
                            >
                                <Select.Option value={1}>显示</Select.Option>
                                <Select.Option value={2}>隐藏</Select.Option>
                            </Select>
                        </Form.Item> */}
                        {/* <Form.Item
                            label="工作性质"
                            style={{}} name="nature_of_work">
                            <Select
                                allowClear
                                placeholder='工作性质'
                                style={{
                                    width: 150
                                }}
                            >
                                <Select.Option value={1}>全职</Select.Option>
                                <Select.Option value={2}>兼职</Select.Option>
                            </Select>
                        </Form.Item> */}
                    </Row>
                    <Row>
                        <Form.Item label="描述" name="msg">
                            <Input placeholder="输入描述关键字" style={{ width: "160px" }}
                                allowClear
                            />
                        </Form.Item>

                        <Form.Item label="接口地址" name="url">
                            <Input placeholder="输入接口地址" style={{ width: "260px" }} allowClear />
                        </Form.Item>
                    </Row>
                    {/* <Form.Item
                        name="rangedate"
                        label="创建时间"
                        style={{ marginLeft: 50 }}
                    >
                        <RangePicker
                            value={''}
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => setDates(val)}
                        // onChange={val => setValue(val)}
                        // onOpenChange={onOpenChange}
                        />
                    </Form.Item> */}

                </SearchView>


                <Table columns={columns} dataSource={tabData}
                    loading={tabLoading}
                    bordered
                    scroll={{ x: 1500 }}
                    pagination={{
                        ...pageConfig,
                        onChange: this.paginationChange
                    }}
                    rowClassName={(record, index) => index % 2 === 0 ? 'ant-table-even-row' : 'ant-table-odd-row'} // 根据奇偶行设置类名

                />




                <MyDraggModal
                    maskClosable={true}
                    footer={null}
                    ref={e => this.recruitmentInfoRef = e}
                >
                    <Descriptions
                        bordered
                        title={"简历-" + recruitmentInfo.resumed_username + "-招聘信息"}
                        size={'default'}
                    // extra={<Button type="primary">Edit</Button>}
                    >
                        <Descriptions.Item label="招聘ID">{recruitmentInfo.id}</Descriptions.Item>
                        <Descriptions.Item label="岗位名称">{recruitmentInfo.job_name}</Descriptions.Item>
                        <Descriptions.Item label="状态">{recruitmentInfo.status_str}</Descriptions.Item>
                        {/* <Descriptions.Item label="Config Info">
          Data disk type: MongoDB
          <br />
          Database version: 3.4
          <br />
          Package: dds.mongo.mid
          <br />
          Storage space: 10 GB
          <br />
          Replication factor: 3
          <br />
          Region: East China 1
          <br />
        </Descriptions.Item> */}
                    </Descriptions>
                </MyDraggModal>
            </div>
        )
    }

    setTabNowPageRefresh = () => {
        this.getTableDataList()
    }

    getDataOnStart = () => {
        this.pageConfig = this.defaultPageConfig
        this.getTableDataList()
    }
    paginationChange = (e) => {
        console.log("切换", e)
        const { pageConfig } = this
        this.pageConfig = {
            ...pageConfig,
            current: e
        }
        this.getTableDataList()

    }
}
