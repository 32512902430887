import React, { Component, useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import App from '../App';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
    LoginOutlined,
} from '@ant-design/icons';
import Login from '../pages/login';
import './index.css'
import Cookies from 'js-cookie';
import { TOKEN } from '../common/utils/contans';
import { getStorage } from '../common/utils/storage.ts';
import ComLayOut from '../compontent/comlayout';

// 引入组件



export default (props) => {
    const navigator=useNavigate()

    useEffect(()=>{
        const token = Cookies.get('Authorization')
        if(!token){
            navigator('/login')
        }
    },[])


    // const Permissions = ({ children }) => {
    //     console.log({ token })
    //     return token ? children : <Navigate to="/login" />;
    // };


    return (
        <>
            {/* {
                token ?
                    <> */}

                        <Routes>
                            <Route  path="/login" element={<Login />}></Route>
                            <Route  path="/*" element={<ComLayOut history={props.history}/>}></Route>
                        </Routes>

                        {/* <Routes>
                        </Routes> */}
                            {/* <ComLayOut /> */}
                           


                        




                    </>
                    // :

                    // <Routes>
                        

                    //     <Route path="/login" element={<Login />}></Route>
                    //     {/* <Route path="/" element={<App />} exact></Route> */}


                    //     <Route path="*" element={<Navigate to="/login" />}></Route>
                    // </Routes>
            // }

    )
}