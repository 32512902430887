import React, { Component, useState, useRef, useEffect } from 'react'
import { Button, Space, Table, Tag, Form, DatePicker, Input, Image, Modal, message, Select, Row,Col } from 'antd';
import SearchView from '../../../compontent/serchview';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { NetGet, NetPost, NetDelete } from '../../../axios_tools';
import { APIS, HOST, PROTOCOL } from '../../../config/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import global from '../../../common/utils/global'
import dayjs from 'dayjs';
import './index.css'
import toTanNumber from '../../../common/tools/toTanNumber';
// import AddYongHuModal from './addModal';
const { RangePicker } = DatePicker;
const { Option } = Select

export default function ArticleView() {
    const [tabLoading, setTabLoading] = useState(true);
    const [tabData, setTabData] = useState([]);
    const [articleCategorySelectData, setArticleCategorySelectData] = useState([]);
    const [childArtTagLoading, setChildArtTagLoading] = useState(true);

    const ref_article_category_id = useRef('')

    let locations = useLocation()

    let SearchViewRef = useRef()
    useEffect(()=>{
        return function(){
            // alert(2)
        }
    },[])

    useEffect(() => {
       
        var location_state = locations.state
        console.log("文章列表页路由参数", location_state)
        if(location_state){
            global.article_table_config ={
                current: 1,
                pageSize: 10,
                total: 0,
            }
        }
        if(location_state && location_state.id){
            ref_article_category_id.current=location_state.id
        }
        getArticleListData(ref_article_category_id.current?{ article_category_id: ref_article_category_id.current } : {});
    }, [locations]);

    function getArticleListData(searchPostData = {}) {
        const { article_table_config } = global
        var postConfig = {
            page: article_table_config.current,
            page_size: article_table_config.pageSize,
            ...searchPostData
        }
        setTabLoading(true);
        NetGet(APIS.article.list, postConfig)
            .then((res) => {
                if (res.code == 0) {
                    global.article_table_config = {
                        ...article_table_config,
                        total: res.data.total
                    }
                    setTabLoading(false);
                    setTabData(res.data.list);
                    return
                }
                message.error(res.message)
            })
    }

    function onSearch(e) {
        console.log(e)

        var searchPostData = e
        if (searchPostData.rangedate && searchPostData.rangedate.length) {
            const rangedate = e.rangedate
            searchPostData.start_time = dayjs(rangedate[0]).format("YYYY-MM-DD HH:mm")
            searchPostData.end_time = dayjs(rangedate[1]).format("YYYY-MM-DD HH:mm")
        }
        delete searchPostData.rangedate
        if (searchPostData.article_category_id) {
            searchPostData.article_category_id = searchPostData.article_category_id.filter((items) => {
                return items
            }).pop()
            ref_article_category_id.current=searchPostData.article_category_id
        }

        global.article_table_config = {
            ...global.article_table_config,
            current: 1
        }
        getArticleListData(searchPostData)
    }

    //删除
    function showDeleteModal(item) {
        const { confirm } = Modal;
        console.log(item)
        confirm({
            title: <div>确定删除文章<span style={{ color: "#6262ef", wordBreak: "break-all" }}>"{item.title}"</span>吗?</div>,
            icon: <ExclamationCircleOutlined />,
            //   content: '退出登录后需重新登录',
            okText: '确定',
            //   centered:"center",
            okType: 'danger',
            cancelText: '取消',
            onOk: () => {
                NetDelete(APIS.article.delete, { ids: [item.id] })
                    .then((e) => {
                        if (e.code == 0) {
                            message.success("删除成功")
                            getArticleListData()
                        } else {
                            message.error(e.msg)
                        }
                    })
            },
            onCancel() {
                //console.log('Cancel');
            },
        });
    }
    const { article_table_config } = global
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            // ellipsis:true,
            width: 100
        },
        {
            title: '标题',
            dataIndex: 'title',
            // ellipsis:true,
            render: (text) => (
                <div
                    className="line-clamp-ellipsis-3"
                    title={text}
                >
                    {text}
                </div>
            ),
        },
        {
            title: '封面图',
            dataIndex: 'cover_image',
            align: "center",
            width: 150,
            render: (text) => (text ? <Image
                width={75}
                height={40}
                onError={(e) => {
                    try {
                        const target = e.target
                        const nextSiblingView = target.nextSibling
                        nextSiblingView.style.display = 'none'
                        target.setAttribute("title", "封面加载失败")
                    } catch (error) {

                    }

                    // ant-image-mask
                }}
                fallback={global.configs.imageFallBack}
                style={{
                    objectFit: "cover"
                }}
                // src={"https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"}
                src={text}
            />
                :
                "/")
        },
        {
            title: '分类',
            dataIndex: 'article_category',
            width: 150,
            render: (e, _item) => <span>
                {e.name}
            </span>
        },
        {
            title: '作者',
            dataIndex: 'author',
            key: 'author',
            width: 100
            // render: (text) => <a>{text}</a>,
        },

        // {
        //     title: '描述',
        //     dataIndex: 'description',
        //     key: "description",

        // },
        
        {
            title: '发布时间',
            dataIndex: 'release_time',
            key: "release_time",
            width: 180,
            align: "center"
            // render: (text) => <a href={text} target="_blank">{text}</a>,
        },

        
        {
            title: '状态',
            dataIndex: 'status_str',
            key: "status_str",
            align: "center",
            width: 100,
            render: (text, _item) => <Tag color={_item.status == 1 ? "#87d068" : "#f50"}>{text}</Tag>,
        },

        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: "created_at",
            width: 180,
            align: "center"
            // render: (text) => <a href={text} target="_blank">{text}</a>,
        },

        {
            title: '浏览量',
            dataIndex: 'views',
            key: "views",
            width: 120,
            // render: (text) => <a href={text} target="_blank">{text}</a>,
        },
        
        {
            title: '排序',
            dataIndex: 'sort',
            key: "sort",
            width: 100,
            // render: (text) => <a href={text} target="_blank">{text}</a>,
        },




        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            align: "center",
            width: 180,
            render: (_, record) => (
                <Space size="middle">
                    <Link to={{ pathname: '/datas/articleEditAdd', state: record }} state={record} data={123}
                        style={{ marginLeft: 15 }}
                    >
                        <Button type="primary" size="small"
                        // onClick={()=>{
                        //     console.log(this.props.history)
                        //     this.props.history.push("/datas/articleEditAdd");
                        // }}

                        >编辑</Button>
                    </Link>
                    <Button type="primary" size="small" danger
                        onClick={() => {
                            showDeleteModal(record)
                        }}
                    >删除</Button>
                </Space>
            ),
        },
    ];

    function shouldUpdate(_, values) {
        const { time_type, rangedate } = values;
        const hasTimeType = !!time_type;
        const hasRangeDate = !!rangedate?.[0] && !!rangedate?.[1];
    
        if ((hasTimeType && !hasRangeDate) || (!hasTimeType && hasRangeDate)) {
          return Promise.reject(new Error('时间和日期必须同时选择或同时为空'));
        }
    
        return Promise.resolve();
      };

    return (
        <div className="pages-configs-bookmark">

            <SearchView
                validateTrigger='onFinish'
                disreset={['article_category_id']}
                ref={SearchViewRef}
                otherButton={
                    <Link to='/datas/articleEditAdd'
                        state={{
                            type: 'add',
                            data: locations.state
                        }}
                        style={{ marginLeft: 15 }}
                    >
                        <Button
                            type="primary"
                        // onClick={()=>{}}
                        >新增文章</Button></Link>
                }
                onSearch={onSearch}
            >


                <Row>

                    
                    <SelectTageView formRef={SearchViewRef.current ? SearchViewRef.current.form : ''} />
                    <Row>
                    <Form.Item
                        label="时间"
                        rules={[
                            {
                              required: false,
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const rangedateValue = getFieldValue('rangedate');
                                if ((!value && !rangedateValue) || (value && rangedateValue)) {
                                  return Promise.resolve();
                                }
                                if(!value)
                                return Promise.reject(new Error('时间类型和时间都必须选择!'));
                              },
                            }),
                          ]}
                        style={{marginRight:2}} name="time_type">
                        <Select
                            allowClear
                            placeholder='请选择类型'
                            style={{
                                width: 120
                            }}
                        >
                            <Select.Option value={1}>发布时间</Select.Option>
                            <Select.Option value={2}>创建时间</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="rangedate"
                        label=""
                        style={{}}
                        rules={[
                            {
                              required: false,
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const time_type_value = getFieldValue('time_type');
                                if ((!value && !time_type_value) || (value && time_type_value)) {
                                  return Promise.resolve();
                                }
                                if(!value)
                                return Promise.reject(new Error('时间类型和时间都必须选择!'));
                              },
                            }),
                          ]}
                    >
                        <RangePicker
                        showTime={{
                            format: 'HH:mm',
                        }}
                        format="YYYY-MM-DD HH:mm"
                            value={''}
                        // disabledDate={disabledDate}
                        // onCalendarChange={val => setDates(val)}
                        // onChange={val => setValue(val)}
                        // onOpenChange={onOpenChange}
                        />
                    </Form.Item>
                    </Row>
                </Row>
                


                            <Row>
                <Form.Item label="文章标题" name="title">
                        <Input placeholder="请输入文章标题" style={{ width: "160px" }} />
                    </Form.Item>

                <Form.Item label="作者" style={{}} name="author">
                    <Input placeholder="请输入作者" style={{ width: "120px" }} />
                </Form.Item>

                <Form.Item
                    label="状态"
                    style={{}} name="status">
                    <Select
                        allowClear
                        placeholder='状态'
                        style={{
                            width: 80
                        }}
                    >
                        <Select.Option value={1}>显示</Select.Option>
                        <Select.Option value={2}>隐藏</Select.Option>
                    </Select>
                </Form.Item>
                </Row>

            </SearchView>


            <Table columns={columns} dataSource={tabData}
                scroll={{ x: 1500 }}
                loading={tabLoading}
                bordered
                pagination={{
                    ...article_table_config,
                    showSizeChanger: true,
                    showQuickJumper: true,

                    showTotal: (total) => `共 ${article_table_config.total} 条`,
                    defaultPageSize: 10,
                    onChange: paginationChange
                }}
                rowClassName={(record, index) => index % 2 === 0 ? 'ant-table-even-row' : 'ant-table-odd-row'} // 根据奇偶行设置类名
            />
        </div>
    )

    function paginationChange(page, pageSize) {
        console.log("切换", page)
        const { article_table_config } = global
        global.article_table_config = {
            ...article_table_config,
            current: page,
            pageSize: pageSize
        }
        getArticleListData(ref_article_category_id.current?{ article_category_id: ref_article_category_id.current } : {})

    }

}








function SelectTageView(props) {
    const [articleCategorySelectData, setArticleCategorySelectData] = useState([])
    const [childArtTagLoading, setChildArtTagLoading] = useState(true)
    let locations = useLocation()

    let formRef = props.formRef


    useEffect(() => {
        if (articleCategorySelectData.length == 0) {
            formRef && getSelectMensData({ pid: 0, floorIndex: 0 }, (data1, res1) => {
                // var location_state = locations.state
                // alert(JSON.stringify(res1))
                setArticleCategorySelectData([res1])
                // location_state&&location_state.id?getSelectMensData({ pid: location_state.id, floorIndex: data1.floorIndex+1,getChild:true },(data2,res2)=>{
                //     setArticleCategorySelectData([res1,res2])
                // }):setArticleCategorySelectData([res1])
            })
        } else {
            setArticleCategorySelectData(articleCategorySelectData.slice(0, 1))
        }



        formRef && setSearchValueForm()

    }, [locations, formRef])


    useEffect(() => {

        if (articleCategorySelectData.length == 0 || articleCategorySelectData.length == 2) {
            return
        }
        var location_state = locations.state
        location_state && location_state.id && getSelectMensData({ pid: location_state.id, floorIndex: 1, getChild: true }, (data2, res) => {
        })
    }, [articleCategorySelectData])



    function setSearchValueForm() {
        var location_state = locations.state
        console.log("setSearchValueForm--文章列表页路由参数", location_state)
        console.log("formRef========", formRef)
        location_state && location_state.id && formRef.setFieldsValue({
            article_category_id: [location_state.id]
        })
    }



    function getSelectMensData(data, callBack) {
        console.log("getSelectMensData收到的参数", data, formRef)
        const { pid, floorIndex, getChild } = data
        var new_articleCategorySelectData = [...articleCategorySelectData]
        new_articleCategorySelectData = new_articleCategorySelectData.slice(0, floorIndex)
        // console.log("new_articleCategorySelectData0000000000",new_articleCategorySelectData)

        //重置文章分类选择结果，防止切换分类后，还显示上一个的id

        // let choosed_art_tag_id = formRef ? formRef.getFieldValue('article_category_id') : null

        // if (choosed_art_tag_id) {
        //     if (!Array.isArray(choosed_art_tag_id)) {
        //         choosed_art_tag_id = Object.values(choosed_art_tag_id)
        //     }
        //     console.log("已选择的值", choosed_art_tag_id)
        //     choosed_art_tag_id = choosed_art_tag_id ? choosed_art_tag_id.slice(0, floorIndex) : []
        //     formRef.setFieldsValue({
        //         article_category_id: choosed_art_tag_id
        //     })
        // }

        //重置文章分类选择结果，防止切换分类后，还显示上一个的id   ----- 结束
        // setArticleCategorySelectData([])
        setChildArtTagLoading(true)

        NetGet(APIS.articleCategory.allList, { pid: pid })
            .then((res) => {

                if (res.code == 0) {
                    if (!res.data.list || res.data.list.length == 0) {

                        setChildArtTagLoading(false)
                        return;
                    }
                    // var new_articleCategorySelectData = [...articleCategorySelectData]
                    new_articleCategorySelectData[floorIndex] = res.data.list

                    setSearchValueForm()


                    setArticleCategorySelectData([...new_articleCategorySelectData])

                    setChildArtTagLoading(false)
                    callBack && callBack(data, res.data.list)
                } else {
                    message.error(res.msg)
                }
            })
    }
    function onArticleCategorySelectChange(e, idata, index) {
        console.log(e, idata, index)
        // this.function ({ mens_num: 2, fa_id: e })
        // formRef.current.setFieldsValue({
        //     type2: '',
        //     type3: ''
        // })
        getSelectMensData({ pid: e, floorIndex: index + 1 }, (data, res) => {
            setArticleCategorySelectData([...articleCategorySelectData, res])
        })
    }

  

    return <Form.Item
        label="文章分类"
        name='article_category_id'
    >
        {/* {fields.map((field) => ( */}
        <Row gutter={0}>
            {articleCategorySelectData.map((item, index) => {
                let zh_floor = toTanNumber(index + 1)
                return <Form.Item

                    name={['article_category_id', index]}
                    style={{marginBottom:0,...(index >= 1 ? { marginLeft: "2px"}:{}),...(index + 1==articleCategorySelectData.length?{marginRight:0}:{}) }}
                    // name={item.}
                    rules={[
                        {
                            required: false,//index==0?true:false,
                            message: `请选择${zh_floor}级分类`,
                        },
                    ]}
                >
                    <Select
                        key={item[0].key}
                        placeholder={`请选择${zh_floor}级分类`}
                        style={{
                            width: "160px"
                        }}
                        disabled={index == 0 && locations.state ? true : ''}//一级不能切换，菜单里进来默认已选择一级
                        onChange={(e, idata) => onArticleCategorySelectChange(e, idata, index)}
                    >
                        {
                            item.map((item1) => <Option key={item1.key} value={item1.id}>{item1.name}</Option>)
                        }
                    </Select>
                </Form.Item>
            })
            }
            {childArtTagLoading ? <Button loading={true} style={{ marginLeft: "15px" }}>获取下级分类中</Button> : null}
        </Row>
    </Form.Item>
}