import React, { Component } from 'react'
import { Menu, message } from 'antd';
import { Link } from 'react-router-dom'
import { UnorderedListOutlined, ContainerOutlined, UserOutlined, TeamOutlined, CoffeeOutlined, IdcardOutlined, FileDoneOutlined } from '@ant-design/icons';
import './index.css'
import { APIS } from '../../config/api';
import { NetGet } from '../../axios_tools';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

export default class LeftMenu extends Component {
    state = {
        openKeys: [],
        childArtTagLoading: true,
        articleCategorySelectData: []
    }
    setChildArtTagLoading = (e) => {
        this.setState({
            childArtTagLoading: e
        })
    }
    setArticleCategorySelectData = (e) => {
        this.setState({
            articleCategorySelectData: e
        })
    }

    componentDidMount() {

        // const getPathLocation = (pathname, callback) => {
        //     var paths = pathname
        //     console.log({paths})

        //     callback && callback(paths)
        //     return paths
        //   }
        //   console.log(this.props.history)
        //   this.props.history.listen(route => getPathLocation(route.pathname, ()=>{}))
        var pathname = window.location.pathname

        var pathname_arr = pathname.split("/")

        this.setState({
            openKeys: pathname == "/" ? ["datalist"] : pathname_arr,
            defaultSelectedKeys: pathname == "/" ? ["datalist"] : pathname_arr,
        })


        this.getSelectMensData({ pid: 0, floorIndex: 0 })

        return;
        var menuitem_dom = document.querySelectorAll(".ant-menu-item")


        menuitem_dom.forEach((item) => {

            item.onmouseover = () => {

                var itemParentNode = item.querySelector(".ant-menu-title-content")
                var itemTxtDom = item.querySelector(".trans-txt")
                var itemOffsetWidth = itemTxtDom.offsetWidth
                var itemParentNodeOffsetWidth = itemParentNode.offsetWidth
                if (itemOffsetWidth > itemParentNodeOffsetWidth) {

                    var translateX = itemOffsetWidth - (itemParentNodeOffsetWidth / 2)//需要滚动的距离px
                    console.log(itemParentNodeOffsetWidth, itemOffsetWidth, translateX)

                    var speed = translateX / 30 //speed 一秒30px需要多少s
                    itemParentNode.style.textOverflow = "unset"
                    itemTxtDom.style.transform = "translateX(-" + (translateX) + "px)"
                    itemTxtDom.style.transition = "all " + speed + "s linear"
                }
                //    this.movetime = setTimeout(()=>{

                //         itemParentNode.onmouseout()
                //     },speed*1000)
            }
            item.onmouseout = () => {
                var itemParentNode = item.querySelector(".ant-menu-title-content")
                var itemTxtDom = item.querySelector(".trans-txt")
                itemParentNode.style.textOverflow = "ellipsis"
                itemTxtDom.style.transform = "unset"
                itemTxtDom.style.transition = "unset"
            }
        })





        // var mens_trans_dom =  document.querySelectorAll(".trans-txt")


        // mens_trans_dom.forEach((item)=>{
        //     var itemParentNode = item.parentNode
        //     console.log(itemParentNode)
        //     var itemOffsetWidth = item.offsetWidth
        //     var itemParentNodeOffsetWidth = itemParentNode.offsetWidth

        //     if(itemOffsetWidth>itemParentNodeOffsetWidth){
        //         itemParentNode.onmouseover=()=>{
        //             var translateX = itemOffsetWidth-(itemParentNodeOffsetWidth/2)//需要滚动的距离px
        //             var speed = translateX/30 //speed 一秒30px需要多少s
        //             itemParentNode.style.textOverflow="unset"
        //             item.style.transform="translateX(-"+(translateX)+"px)"
        //             item.style.transition="all "+speed+"s linear"

        //         //    this.movetime = setTimeout(()=>{

        //         //         itemParentNode.onmouseout()
        //         //     },speed*1000)
        //         }
        //         itemParentNode.onmouseout = ()=>{
        //             itemParentNode.style.textOverflow="ellipsis" 
        //             item.style.transform="unset"
        //             item.style.transition="unset"
        //         }
        //     }


        // })
    }


    getSelectMensData = (data, callBack) => {
        console.log("菜单-getSelectMensData收到的参数", data)
        const { pid, floorIndex, getChild } = data
        const { articleCategorySelectData } = this.state
        var new_articleCategorySelectData = [...articleCategorySelectData]
        new_articleCategorySelectData = new_articleCategorySelectData.slice(0, floorIndex)



        //重置文章分类选择结果，防止切换分类后，还显示上一个的id   ----- 结束
        this.setArticleCategorySelectData(new_articleCategorySelectData)
        this.setChildArtTagLoading(true)

        NetGet(APIS.articleCategory.allList, { pid: pid })
            .then((res) => {

                if (res.code == 0) {
                    if (!res.data.list || res.data.list.length == 0) {
                        this.setChildArtTagLoading(false)
                        return;
                    }
                    new_articleCategorySelectData = [...articleCategorySelectData]
                    new_articleCategorySelectData[floorIndex] = res.data.list
                    this.setArticleCategorySelectData(new_articleCategorySelectData)
                    this.setChildArtTagLoading(false)
                    callBack && callBack()
                } else {
                    message.error(res.msg)
                }
            })
    }

    onOpenChange = (keys) => {
        console.log(keys)
        this.setState({
            openKeys: keys
        })
        // const { openKeys } = this.state
        // const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
        // const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        // if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        //     this.setState({
        //         openKeys: keys
        //     });
        // } else {
        //     this.setState({
        //         openKeys: latestOpenKey ? [latestOpenKey] : []
        //     });
        // }
    }

    onClick = (e) => {
        console.log(e)
    }
    render() {
        const { openKeys, defaultSelectedKeys, childArtTagLoading,
            articleCategorySelectData } = this.state
        console.log('菜单==', articleCategorySelectData)
        const articleCategMenusItemArr = articleCategorySelectData.map((item, index) => {
            return item.map((_item, _index) => {
                return getItem(<Link to={`/artTag/${index}/${_item.key}`} state={_item}>{_item.name}</Link>, _item.key)
            })
        })

        const articleCategMenusItemArr_floorindex1 = articleCategMenusItemArr ? articleCategMenusItemArr[0] : []
        console.log('菜单==articleCategMenusItemArr_floorindex1', articleCategMenusItemArr_floorindex1)
        const items = [
            // getItem(<Link to="/datas/articleTags">分类管理</Link>, 'articleTags', <UnorderedListOutlined />),
            // getItem('Navigation Two', '2', <CalendarOutlined />),
            // getItem("数据统计", 'datalist', <AppstoreOutlined />, [
            //   getItem(<Link to="/datalist/plugdata">插件数据</Link>, 'plugdata'),
            // ],true),
            // getItem('配置管理', 'configs', <SettingOutlined />, [
            //   getItem(<Link to="/configs/defaultsetting">基础设置</Link>, 'defaultsetting'),
            //   getItem(<Link to="/configs/querysetting">搜索参数</Link>, 'querysetting'),
            //   getItem(<Link to="/configs/bookmarksetting">书签管理</Link>, 'bookmarksetting'),
            // ]),
            getItem(
                '文章管理',// <Link to="/datas/article">文章管理</Link>,
                'article',
                <ContainerOutlined />,
                articleCategMenusItemArr_floorindex1,
                false
            ),
            ...(this.props.userInfo&&this.props.userInfo.role_id=='admin'?
                [
                    getItem(<Link to="/config/recruitment">招聘管理</Link>, 'recruitment', <CoffeeOutlined />),
                    getItem(<Link to="/config/leadteam">领导班子</Link>, 'leadteam', <TeamOutlined />),
                    getItem(<Link to="/datalist/resumedelivery">简历投递</Link>, 'resumedelivery', <IdcardOutlined />),
                    getItem(<Link to="/config/userlist">账号管理</Link>, 'userlist', <UserOutlined />),
                    getItem(<Link to="/datalist/logs">日志记录</Link>, 'logs', <FileDoneOutlined />)
                ]
                :
                []
            )

        ];
        const local_path = window.location.hash
        const local_path_arr = local_path.split("/")
        console.log(local_path_arr)

        const pathname = window.location.pathname
        return (
            <Menu
                onClick={this.onClick}
                mode="inline"
                theme={'dark'}
                selectable
                // openKeys={openKeys}
                defaultSelectedKeys={(local_path == '/' || !local_path) ? "article" : local_path_arr}
                onOpenChange={this.onOpenChange}
                defaultOpenKeys={['article']}
                items={items}
                className="menus-styles"
            />
        )
    }
}
