/**
 * 防止过快点击
 * @param {*} fun 要执行的方法 
 * @param {*} time 须间隔的时间 单位秒
 */
function banFastHandle(fun,time=1){
  if(!window.firstDate){
    window.firstDate = new Date().getTime()
    fun()
    return false
  }

  var nowDate = new Date().getTime()
  if(window.firstDate&&nowDate>window.firstDate+time*1000){
    window.firstDate= new Date().getTime()
    fun()
    return false
  }
  
  console.info("不要过快点击哦！")

}


export default banFastHandle