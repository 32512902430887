import React, { useState } from 'react';
import { Tree, Button, Modal, Form, Input } from 'antd';

const ArticleTags = () => {
  const [treeData, setTreeData] = useState([
    {
      key: '1',
      title: '导航1',
      children: [
        {
          key: '2',
          title: '导航1-1',
          children: [
            {
              key: '3',
              title: '导航1-1-1',
              children: [],
            },
          ],
        },
        {
          key: '4',
          title: '导航1-2',
          children: [],
        },
      ],
    },
  ]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editNode, setEditNode] = useState(null);
  const [form] = Form.useForm();

  const handleEditNode = (node) => {
    setEditNode(node);
    form.setFieldsValue({ title: node.title });
    setIsModalVisible(true);
  };

  const handleDeleteNode = (node) => {
    const updatedTreeData = deleteNodeFromTree(treeData, node.key);
    setTreeData(updatedTreeData);
  };

  const handleAddChildNode = (node) => {
    setEditNode({ parentId: node.key });
    form.resetFields();
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setEditNode(null);
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleModalOk = () => {
    form.validateFields().then((values) => {
      const { title } = values;
      if (editNode) {
        const updatedTreeData = updateNodeInTree(treeData, editNode.key, { title });
        setTreeData(updatedTreeData);
      } else {
        const newNode = {
          key: generateNodeId(),
          title,
          children: [],
        };
        if (editNode && editNode.parentId) {
          const updatedTreeData = addNodeToTree(treeData, editNode.parentId, newNode);
          setTreeData(updatedTreeData);
        } else {
          setTreeData([...treeData, newNode]);
        }
      }
      setEditNode(null);
      form.resetFields();
      setIsModalVisible(false);
    });
  };

  const generateNodeId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const addNodeToTree = (tree, parentId, newNode) => {
    return tree.map((node) => {
      if (node.key === parentId) {
        return {
          ...node,
          children: [...(node.children || []), newNode],
        };
      } else if (node.children) {
        return {
          ...node,
          children: addNodeToTree(node.children, parentId, newNode),
        };
      }
      return node;
    });
  };

  const updateNodeInTree = (tree, nodeId, updatedNode) => {
    return tree.map((node) => {
      if (node.key === nodeId) {
        return {
          ...node,
          ...updatedNode,
        };
      } else if (node.children) {
        return {
          ...node,
          children: updateNodeInTree(node.children, nodeId, updatedNode),
        };
      }
      return node;
    });
  };

  const deleteNodeFromTree = (tree, targetNodeId) => {
    return tree.map((node) => {
      if (node.key === targetNodeId) {
        return null;
      } else if (node.children) {
        return {
          ...node,
          children: deleteNodeFromTree(node.children, targetNodeId),
        };
      }
      return node;
    }).filter(Boolean);
  };

  const renderTreeNodes = (data) => {
    return data.map((node) => {
      const { key, title, children } = node;
      const titleWithButtons = (
        <span>
          <span onClick={() => handleEditNode(node)}>{title}</span>
          <Button type="link" size="small" onClick={() => handleAddChildNode(node)}>
            添加
          </Button>
          <Button type="link" size="small" onClick={() => handleDeleteNode(node)}>
            删除
          </Button>
        </span>
      );

      if (children && children.length > 0) {
        return (
          <Tree.TreeNode key={key} title={titleWithButtons}>
            {renderTreeNodes(children)}
          </Tree.TreeNode>
        );
      }
      return <Tree.TreeNode key={key} title={titleWithButtons} />;
    });
  };

  return (
    <div>
      <Tree showLine defaultExpandAll>
        {renderTreeNodes(treeData)}
      </Tree>

      <Modal
        title={editNode ? '编辑导航项' : '添加导航项'}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
      >
        <Form form={form} name="navForm">
          <Form.Item name="title" label="导航项标题" rules={[{ required: true, message: '请输入导航项标题' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ArticleTags;
