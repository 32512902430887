import React, { Component, useState, useRef, useEffect } from 'react'
import {
    Button,
    Form,
    InputNumber,
    Row,
    Select,
    message,
    Upload,
    Input,
    DatePicker,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import './index.css'
import { NetPost, NetGet, NetPatch } from '../../../../axios_tools';
import { HOST, APIS } from '../../../../config/api';
import toTanNumber from '../../../../common/tools/toTanNumber';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import global from '../../../../common/utils/global';

const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
    labelCol: {
        span: 3,
    },
    // wrapperCol: {
    //     span: '18',
    // },
};
const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e ?.fileList;
};

const ArticleEditAdd = (props) => {
    let locations = useLocation()
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState('')
    const [fileList, setFileList] = useState([])
    const [cover_image, setCoverImage] = useState('')
    const [childArtTagLoading, setChildArtTagLoading] = useState(true)
    const [articleCategorySelectData, setArticleCategorySelectData] = useState([])
    // state = {
    //     loading: false,
    //     imageUrl: "",
    //     childArtTagLoading: true,
    //     articleCategorySelectData: []//[[],[]]
    // }
    let allow_post = useRef(true)
    let formRef = useRef({})
    let isEdit = useRef(false)
    let editData = useRef({})
    let editor = useRef('')
    let defaultcover_image = useRef('')


    function getSelectMensData(data, callBack) {
        console.log("getSelectMensData收到的参数", data)
        const { pid, floorIndex, getChild } = data
        var new_articleCategorySelectData = [...articleCategorySelectData]
        new_articleCategorySelectData = new_articleCategorySelectData.slice(0, floorIndex)

        //重置文章分类选择结果，防止切换分类后，还显示上一个的id
        let choosed_art_tag_id = formRef.current.getFieldValue('article_category_id')
        if (!Array.isArray(choosed_art_tag_id)) {
            choosed_art_tag_id = Object.values(choosed_art_tag_id)
        }
        console.log("已选择的值", choosed_art_tag_id)
        choosed_art_tag_id = choosed_art_tag_id ? choosed_art_tag_id.slice(0, floorIndex) : []
        formRef.current.setFieldsValue({
            article_category_id: choosed_art_tag_id
        })
        //重置文章分类选择结果，防止切换分类后，还显示上一个的id   ----- 结束
        setArticleCategorySelectData(new_articleCategorySelectData)
        setChildArtTagLoading(true)

        NetGet(APIS.articleCategory.allList, { pid: pid })
            .then((res) => {

                if (res.code == 0) {
                    if (!res.data.list || res.data.list.length == 0) {
                        setChildArtTagLoading(false)
                        return;
                    }
                    new_articleCategorySelectData = [...articleCategorySelectData]
                    new_articleCategorySelectData[floorIndex] = res.data.list
                    setArticleCategorySelectData(new_articleCategorySelectData)
                    setChildArtTagLoading(false)
                    callBack && callBack()
                } else {
                    message.error(res.msg)
                }
            })
    }
    function onArticleCategorySelectChange(e, idata, index) {
        console.log(e, idata, index)
        // this.function ({ mens_num: 2, fa_id: e })
        // formRef.current.setFieldsValue({
        //     type2: '',
        //     type3: ''
        // })
        getSelectMensData({ pid: e, floorIndex: index + 1 })
    }




    function onFinish(value) {
        console.log("提交的表单", value)
        // if(!this.isCanPostForm){
        //    return message.error("请上传封面")
        // }
        var postData = { ...value }
        if (isEdit.current) {
            // postData.cover_image = editData.current.cover_image
            postData.isEdit = isEdit.current
            postData.id = editData.current.id
            // postData.cover_image = defaultcover_image.current

        }
        if (postData.cover_image && postData.cover_image[0] && postData.cover_image[0].response) {
            postData.cover_image = postData.cover_image[0].response.data.files_path[0]
        } else {
            if (!postData.cover_image || postData.cover_image.length == 0) {
                postData.cover_image = '' //编辑的时候删除了图片
            } else {
                postData.cover_image = defaultcover_image.current
            }
        }


        postData.content = editor.current.getContent()
        postData.article_category_id = postData.article_category_id.filter((items) => {
            return items
        })
        postData.article_category_id = postData.article_category_id.pop()
        if (postData.release_time) {
            postData.release_time = dayjs(postData.release_time).format("YYYY-MM-DD HH:mm")
        }
        console.log("提交得数据", { postData })
        if (!allow_post.current) {
            return false
        }
        allow_post.current = false
        var NetObject = postData.isEdit ? NetPatch : NetPost
        var NetApi = postData.isEdit ? APIS.article.edit : APIS.article.add
        NetObject(NetApi, postData)
            .then((res) => {
                if (res.code == 0) {
                    console.log("props.getTableData", props.getTableData)
                    message.success(res.message)
                    setTimeout(() => {
                        allow_post.current = true
                        if (!postData.isEdit) {
                            let { article_table_config } = global
                            article_table_config.current = 1
                        }
                        props.history.back()
                    }, 500)
                } else {
                    allow_post.current = true
                    message.error(res.message)
                }

            })
            .catch(() => {
                allow_post.current = true
            })
    }



    function getSelectDataNoChoose_edit(data) {
        return new Promise((resp, ejcp) => {
            console.log("getSelectDataNoChoose_edit收到的参数", data)
            const { pid, floorIndex, getChild } = data
            //重置文章分类选择结果，防止切换分类后，还显示上一个的id   ----- 结束
            setChildArtTagLoading(true)

            NetGet(APIS.articleCategory.allList, { pid: pid })
                .then((res) => {

                    if (res.code == 0) {
                        if (!res.data.list ||res.data.list.length==0) {
                            setChildArtTagLoading(false)
                            return;
                        }
                        setChildArtTagLoading(false)
                        resp(res.data.list)
                    } else {
                        message.error(res.msg)
                    }
                })
        })
    }

    useEffect(() => {
        // getSelectDataNoChoose_edit()
    }, [articleCategorySelectData])

    useEffect(() => {



        console.log("路由参数", locations.state)
        var location_state = locations.state
        console.log("location_state", location_state)
        editor.current = window.UE.getEditor('editor', {
            // ... 更多配置
            toolbars: [[
                "fullscreen",   // 全屏
                // "source",       // 源代码
                "undo",         // 撤销
                "redo",         // 重做
                "|",
                "bold",         // 加粗
                "italic",       // 斜体
                "underline",    // 下划线
                "fontborder",   // 字符边框
                "strikethrough",// 删除线
                "superscript",  // 上标
                "subscript",    // 下标
                "removeformat", // 清除格式
                "formatmatch",  // 格式刷
                "autotypeset",  // 自动排版
                "blockquote",   // 引用
                "pasteplain",   // 纯文本粘贴模式
                "|",
                "forecolor",    // 字体颜色
                "backcolor",    // 背景色
                "insertorderedlist",   // 有序列表
                "insertunorderedlist", // 无序列表
                "selectall",    // 全选
                "cleardoc",     // 清空文档
                "|",
                "rowspacingtop",// 段前距
                "rowspacingbottom",    // 段后距
                "lineheight",          // 行间距
                "|",
                "customstyle",         // 自定义标题
                "paragraph",           // 段落格式
                "fontfamily",          // 字体
                "fontsize",            // 字号
                "|",
                // "directionalityltr",   // 从左向右输入
                // "directionalityrtl",   // 从右向左输入
                "indent",              // 首行缩进
                "|",
                "justifyleft",         // 居左对齐
                "justifycenter",       // 居中对齐
                "justifyright",
                "justifyjustify",      // 两端对齐
                "|",
                // "touppercase",         // 字母大写
                // "tolowercase",         // 字母小写
                "link",                // 超链接
                "unlink",              // 取消链接
                "anchor",              // 锚点
                "|",
                "imagenone",           // 图片默认
                "imageleft",           // 图片左浮动
                "imageright",          // 图片右浮动
                "imagecenter",         // 图片居中
                "|",
                "simpleupload",        // 单图上传
                "insertimage",         // 多图上传
                "emotion",             // 表情
                // "scrawl",              // 涂鸦
                "insertvideo",         // 视频
                "attachment",          // 附件
                // "insertframe",         // 插入Iframe
                // "insertcode",          // 插入代码
                // "pagebreak",           // 分页
                // "template",            // 模板
                "background",          // 背景
                // "formula",             // 公式
                "|",
                "horizontal",          // 分隔线
                // "date",                // 日期
                // "time",                // 时间
                "spechars",            // 特殊字符
                // "wordimage",           // Word图片转存
                "|",
                "inserttable",         // 插入表格
                "deletetable",         // 删除表格
                "insertparagraphbeforetable",     // 表格前插入行
                "insertrow",           // 前插入行
                "deleterow",           // 删除行
                "insertcol",           // 前插入列
                "deletecol",           // 删除列
                "mergecells",          // 合并多个单元格
                "mergeright",          // 右合并单元格
                "mergedown",           // 下合并单元格
                "splittocells",        // 完全拆分单元格
                "splittorows",         // 拆分成行
                "splittocols",         // 拆分成列
                "|",
                // "print",               // 打印
                "preview",             // 预览
                "searchreplace",       // 查询替换
                // "help",                //      
            ]],
            elementPathEnabled: false,
            // UEDITOR_HOME_URL:'http://localhost:3000/UE/',
            // UEDITOR_CORS_URL:'http://192.168.0.188:9502/',
            serverUrl: HOST + '/admin/common/uEditorUploadFile',
            serverHeaders: {
                'Authorization': Cookies.get('Authorization'),
            },
            initialContent: location_state ? location_state.content || '' : ''
        });

        editor.current.addListener("destroy", function () {
            console.log('编辑器已经销毁');
        })

        console.log("编辑器实例", editor.current)

        setTimeout(() => { console.log(editor.current.getContent()) }, 5000)



        var parms_data = ''

        if (location_state.type == 'add') {

        } else {
            parms_data = location_state ? { ...location_state } : ''
        }

        // if(parms_data&&parms_data.content&&typeof parms_data.content == 'string'){
        //     parms_data.content=JSON.parse(parms_data.content)
        //     console.log("parse后得跳转数据",parms_data)
        // }
        if (!parms_data) {
            // getSelectMensData({ pid: 0, floorIndex: 0 },()=>{
                if (!(location_state && location_state.data && location_state.data.id)) {
                    getSelectMensData({ pid: 0, floorIndex: 0 })
                    return;
                  }
            setSelectItems([location_state.data.id])
            formRef.current.setFieldsValue({
                article_category_id: [location_state.data.id]
            })
            // getSelectMensData({ pid: location_state.data.id, floorIndex: 2 })
            // })

            return;
        }
        // console.log("跳转数据-转换表单前", parms_data)

        parms_data.all_parent_category_ids.reverse()
        parms_data.all_parent_category_ids.push(parms_data.article_category_id)
        const { all_parent_category_ids } = parms_data
        parms_data.article_category_id = all_parent_category_ids
        // formRef.current.setFieldsValue({
        //     article_category_id:all_parent_category_ids
        // })

        defaultcover_image.current = parms_data.cover_image
        isEdit.current = true
        if (parms_data.cover_image) {
            parms_data.cover_image = [
                {
                    status: 'done',
                    url: parms_data.cover_image,
                    thumbUrl: parms_data.cover_image,
                }
            ]
        } else {
            parms_data.cover_image = []
        }
        console.log("formRef", formRef)
        parms_data.release_time = dayjs(parms_data.release_time)
        // parms_data.content=BraftEditor.createEditorState(parms_data.content)
        // console.log("跳转数据-转换表单后", parms_data)
        // delete parms_data.article_category_id
        formRef.current.setFieldsValue(parms_data)
        editData.current = parms_data

        async function setSelectItems(ids) {
            var selectDataList = []
            // getSelectDataNoChoose_edit({ pid: 0, floorIndex: 0 },(list)=>{
            // setTimeout(()=>{
            for (let index = 0; index < [0, ...ids].length ; index++) {
                const element = [0, ...ids][index];
                const itemSelcData = await getSelectDataNoChoose_edit({ pid: element, floorIndex: index })
               
               console.log("循环请求结果itemSelcData",itemSelcData)
                selectDataList.push(itemSelcData)

                // ,(list)=>{
                //     setArticleCategorySelectData(list)
                //     // formRef.current.setFieldsValue({
                //     //     article_category_id:[7,15]
                //     // })
                // })
                console.log('selectDataList============',selectDataList)
            setArticleCategorySelectData(selectDataList)
            }
            


            // },6600)
            // })








            // editor.current.setContent('<p>测试内容</p>')

            // 异步设置编辑器内容
            // setTimeout(() => {
            //   this.props.form.setFieldsValue({
            //     content: BraftEditor.createEditorState('<p>Hello <b>World!</b></p>')
            //   })
            // }, 1000)

        }
        setSelectItems(all_parent_category_ids)
    }, [])

    useEffect(() => {

        // 这里return 一个回调函数，在函数中清除副作用
        return () => {
            //销毁editor，避免再次路由到这个页面，不创建editor实例的bug
            console.log("销毁editor.current", editor.current)
            editor.current && editor.current.destroy()
            editor.current && (editor.current = '')
        }
    }, [])

    function uploadChange(info) {
        // this.isCanPostForm = false
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            console.log({ info })
            const { file, fileList } = info
            var response = file.response
            console.log("response", response)

            if (response.code == 0) {
                setFileList(fileList)
                setCoverImage(response.data.path)

                // this.isCanPostForm = true
            } else {
                fileList[0].status = "error"
                setFileList([])
                setCoverImage('')


                message.error(response.message)

            }
        }
    }


    // const controls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'link', 'separator', 'media' ]


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                点击上传
              </div>
        </div>
    );
    console.log("render===articleCategorySelectData",articleCategorySelectData)
    return (
        <div className="content">
            <div className="wenzhang-edit-add-view">
                <Form
                    ref={formRef}
                    name="validate_other"
                    {...formItemLayout}
                    onFinish={onFinish}
                    initialValues={{
                        'status': 1,
                        'article_category_id': [''],
                        'content': 1,//验证富文本用
                    }}
                >


                    <Form.Item label="文章标题"
                        name='title'
                        rules={[
                            {
                                required: true,
                                message: '请填写文章标题',
                            },
                        ]}
                    >
                        <Input placeholder="请输入文章标题" />
                    </Form.Item>


                    <Form.Item
                        label="文章分类"
                        name='article_category_id'
                        required={true}
                    >
                        {/* {fields.map((field) => ( */}
                        <Row gutter={0}>
                            {articleCategorySelectData.map((item, index) => {
                                let zh_floor = toTanNumber(index + 1)
                                return <Form.Item

                                    name={['article_category_id', index]}
                                    style={index >= 1 ? { marginLeft: "15px" } : {}}
                                    // name={item.}
                                    rules={[
                                        {
                                            required: index == 0 ? true : false,
                                            message: `请选择${zh_floor}级分类`,
                                        },
                                    ]}
                                >
                                    <Select
                                        key={item[0].key}
                                        placeholder={`请选择${zh_floor}级分类`}
                                        style={{
                                            width: "200px"
                                        }}
                                        onChange={(e, idata) => onArticleCategorySelectChange(e, idata, index)}
                                    >
                                        {
                                            item.map((item1) => {return item1?<Option key={item1.key} value={item1.id}>{item1.name}</Option>:null})
                                        }
                                    </Select>
                                </Form.Item>

                            })
                            }
                            {childArtTagLoading ? <Button loading={true} style={{ marginLeft: "15px" }}>获取下级分类中</Button> : null}
                        </Row>
                    </Form.Item>




                    {/* 
                        <Form.Item label="文章作者"
                            required={true}
                        >
                            <Row gutter={0}>
                                <Form.Item style={{ marginLeft: 0 }}
                                    name="author"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请填写文章作者',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入作者" style={{ width: "150px" }} />
                                </Form.Item>
                                <Form.Item style={{ marginLeft: 20 }}
                                    name="origin"
                                    // initialValue={1}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择',
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        <Radio value={1}>原创</Radio>
                                        <Radio value={2}>来自网络</Radio>
                                        <Radio value={3}>是否推荐</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Row>
                        </Form.Item> */}


                    <Form.Item

                        name="content"
                        label="文章内容"
                        rules={[{ required: true, message: '必须填写文章内容' },
                        {
                            validator: (_, value) => {
                                console.log("----", editor.current.getContent())
                                if (!editor.current.getContent()) {

                                    return Promise.reject(new Error('必须填写文章内容'));
                                } else {
                                    return Promise.resolve()

                                }
                            },


                        },
                        ]}
                    >
                        <Input style={{}} type='hidden' />
                        <div
                            id='editor'
                            className="my-editor"
                            style={{ height: "500px", width: '100%' }}
                            // controls={controls}
                            placeholder="请输入正文内容"

                            media={
                                {
                                    uploadFn: myUploadFn,
                                    externals: {
                                        image: true,
                                        video: true,
                                        audio: true,
                                        embed: false
                                    }
                                }

                            }
                        ></div>
                    </Form.Item>


                    {/* <Form.Item
                            name="upload"
                            label="上传封面"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            extra=""
                        >
                            <Upload name="logo" action="/upload.do" listType="picture">
                                <Button icon={<UploadOutlined />}>点击上传</Button>
                            </Upload>
                        </Form.Item> */}

                    <Form.Item label="描述"
                        name="description"
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: '请填写',
                    //     },
                    // ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>



                    {/* <Form.List
                            label="文章分类"
                           
                            required={true}
                        >

                            {(fields, { add, remove }) => {
                                console.log('fields===', fields)
                                return */}

                    {/* ))} */}
                    {/* </Form.Item>
                            }}
                        </Form.List> */}

                    <Form.Item name="cover_image" label="上传封面"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        extra=""
                    // rules={[
                    //     {
                    //         required: true,
                    //         message: '请上传封面',
                    //     },
                    // ]}
                    >
                        <Upload
                            accept="image/*"
                            name='files[]'
                            action={APIS.public.uploadFiles}
                            maxCount={1}
                            listType="picture-card"
                            // listType="picture"
                            data={{
                                file_type: 'image'
                            }}
                            headers={
                                {
                                    Authorization: Cookies.get('Authorization')

                                }
                            }
                            defaultFileList={fileList}
                            onChange={uploadChange}
                        // customRequest={(a,b)=>{
                        //     const fmData = new FormData();
                        //     fmData.append("files",[a.file])
                        //     fmData.append("file_type",'image')
                        //     NetPost(APIS.public.uploadFiles,fmData)
                        //     console.log(a,b )
                        // }}
                        >
                            {imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt="avatar"
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                    uploadButton
                                )}
                        </Upload>
                    </Form.Item>


                    <Form.Item label="状态"
                        name='status'
                        rules={[
                            {
                                required: true,
                                message: '请选择状态',
                            },
                        ]}
                    >
                        <Select
                            style={{
                                width: "200px"
                            }}
                        >
                            <Option value={1}>显示</Option>
                            <Option value={2}>隐藏</Option>
                        </Select>
                    </Form.Item>


                    <Form.Item label="发布时间"
                        name='release_time'
                        rules={[
                            {
                                required: true,
                                message: '请选择发布时间',
                            },
                        ]}

                    >
                        <DatePicker
                            showTime={{
                                format: 'HH:mm',
                            }}
                            format="YYYY-MM-DD HH:mm"
                            style={{ width: "200px" }} />
                    </Form.Item>


                    <Form.Item label="作者"
                        name='author'

                    >
                        <Input
                            min={0}
                            style={{ width: "150px" }} />
                    </Form.Item>



                    <Form.Item label="排序"
                        name='sort'

                    >
                        <InputNumber
                            min={0}
                            style={{ width: "150px" }} />
                    </Form.Item>

                    <Form.Item

                        style={{
                            justifyContent: "center"
                        }}
                        className="sub-modal-btn-box"
                    >
                        <Button className="add-daohang-modal-btn"
                            onClick={() => {
                                props.history.back()
                            }}
                        >
                            取消
                        </Button>
                        <Button className="add-daohang-modal-btn" type="primary" htmlType="submit">
                            确认
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>


    )

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    function handleChange(info) {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };


    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };





    function myUploadFn(param) {

        const serverURL = window.myurl.url + window.myurl.article_media
        const xhr = new XMLHttpRequest
        const fd = new FormData()

        const successFn = (response) => {
            // console.log({response})
            // 假设服务端直接返回文件上传后的地址
            // 上传成功后调用param.success并传入上传后的文件地址
            var res_data = JSON.parse(xhr.responseText)
            console.log("上传uccessFn", { res_data })
            param.success({
                url: window.myurl.url + res_data.data.path,
                meta: {

                    autoPlay: true, // 指定音视频是否自动播放
                    controls: true, // 指定音视频是否显示控制栏
                }
            })

        }

        const progressFn = (event) => {
            // 上传进度发生变化时调用param.progress
            param.progress(event.loaded / event.total * 100)
        }

        const errorFn = (response) => {
            console.log('上传失败errorFn', response)
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.'
            })
        }

        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)

        fd.append('file', param.file)
        fd.append('token', Cookies.get('Authorization'))
        xhr.open('POST', serverURL, true)
        xhr.send(fd)

    }

}


export default ArticleEditAdd