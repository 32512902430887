
import axios from 'axios';
// import MProgress from '../components/progress'
// import { toast } from 'react-toastify';
import { message } from 'antd';
// import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Cookies from 'js-cookie';
import { HOST } from '../config/api';
import { encryptData, decryptData } from '../common/tools/serviceKey'
import banFastHandle from '../common/utils/banFastHandle';
import { modLoginOut } from '../common/tools/ex_login';
export const history = createBrowserHistory();
// var allurl = ''
var location=window.location
const instance = axios.create({
    baseURL: HOST,//location.protocol+'//'+location.hostname+":8080",//'https://xudaxianer.cn',////
    withCredentials: process.env.NODE_ENV=='development'?true:false,

    timeout: 20 * 1000,
    // headers: { 'X-Custom-Header': 'foobar' }
    // headers:{

    // }
});
var token = ''
var isToLogin = true
//请求拦截处理
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // MProgress.start();
    // allurl = config.url
    // if(process.env.NODE_ENV=='development'){
    //     Cookies.set('token', '123', { expires: 1000 })
    //   }
    token = Cookies.get('Authorization')
    config.headers.Authorization = token
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

const isDev = process.env.NODE_ENV == 'development'

function tranCryptoJSEnv(eventData, type) {
    //除了开发环境，还有96ck测试环境不用加密解密
    if (isDev||location.host.indexOf('96ck')>=0) {
        return eventData
    }

    // console.log('axiox-params-' + type + '原数据', eventData)
    let crypParams = eventData ? encryptData(eventData) : eventData
    // console.log('axiox-params-' + type + '-加密', crypParams)

    // console.log("请求发送原数据---",eventData)
    return {params:crypParams}
}


function tranJsonDec(eventData, type){
    //除了开发环境，还有96ck测试环境不用加密解密
    if (isDev||location.host.indexOf('96ck')>=0) {
        return eventData
    }
    return decryptData(eventData)
}




//返回拦截处理
instance.interceptors.response.use(function (response) {
    if(response.data&&response.data.data){
        response.data.data = tranJsonDec(response.data.data)
    }
    // console.log("请求返回解密数据---",response.data.data)
    // response.data&&(res.data.data = tranJsonDec(res.data.data),"res-get")

    //登录过期清空token 并跳转到登录
    if(response.data&&response.data.code=="1007"||response.data.code=='1006'){

        banFastHandle(()=>{
            message.error("登录过期，请重新登录")
            modLoginOut()
            // Cookies.set('Authorization', '', { expires: 1000 })
            // history.push("/login")
            // history.go()
        },1.5)
            return false

    }

    // 对响应数据做点什么
    // MProgress.done();
    return response;
}, function (error) {
// 在这里可以访问到请求的地址
console.log('---------------------------Request URL:', error.config.url); // 输出请求的地址
    //__hasCaught  如果外部调用后，并且有使用.catch方法，则不执行后面的message代码


    //特定接口直接返回reject
    if(error.config&&(error.config.url=='/admin/admin/userinfo'||error.config.url=='/admin/admin/captcha')){
        return Promise.reject(error);
    }

    // 对响应错误做点什么
    // MProgress.done();
    console.log("interceptors.response-error", error, Object.keys({ ...error }))
    // if (error.__hasCaught) {
    //     return Promise.reject(error);
    // }
    if (!error.response) {
        if (error.message.indexOf("timeout") > -1) {
            message.warning("链接请求超时")
        } else {
            message.warning("网络错误，请检查网络")
        }
    } else {
        switch (error.response.status) {
            case 400:
                message.warning("错误请求")
                break;
            case 403:
                message.warning("拒绝访问")
                break;
            case 404:
                message.warning("请求错误，未找到该资源")
                break;
            case 408:
                message.warning("请求超时")
                break;
            case 500:
                message.warning("服务器端出错")
                break;
            case 501:
                message.warning("网络未实现")
                break;
            case 502:
                message.warning("网络错误")
                break;
            case 503:
                message.warning("服务不可用")
                break;
            case 504:
                message.warning("网络超时")
                break;
            default:
                message.warning(`连接错误${error.response.status}`)
                break;
        }
    }
    return Promise.reject(error);

});


export const NetPost = async (api, params) => {

    return new Promise((resolve, reject) => {


        instance.post(api, tranCryptoJSEnv(params,'post'))
            .then(res => {
                resolve(res.data)
            })
            .catch(error => {
                console.log("error-NetPost", error)
                reject(error)
            })
    })
}


export const NetGet = async (api, params) => {

    return new Promise((resolve, reject) => {
        console.log("NetGet-params",params)

        instance.get(api, { params: tranCryptoJSEnv(params,'get') })
            .then(res => {
                console.log(res)
                resolve(res.data)
            })
            .catch(error => {
                console.log("error-NetGet", error)
                reject(error)
            })
    })


}


export const NetDelete = async (api, params) => {

    return new Promise((resolve, reject) => {


        instance.delete(api, { params: tranCryptoJSEnv(params,'delete') })
            .then(res => {
                console.log(res)
                resolve(res.data)
            })
            .catch(error => {
                console.log("error-NetDelete", error)
                reject(error)
            })
    })


}

export const NetPatch = async (api, params) => {

    return new Promise((resolve, reject) => {


        instance.patch(api, tranCryptoJSEnv(params,'patch'))
            .then(res => {
                console.log(res)
                resolve(res.data)
            })
            .catch(error => {
                console.log("error-NetPatch", error)
                reject(error)
            })
    })


}


