// import { useNavigate } from "react-router-dom";
import history from "../../routers/history";
// import { clearStorage } from "../utils/storage.ts";
import Cookies from 'js-cookie'

export const modLoginOut = ()=> {
    // const navigator = useNavigate();
    // clearStorage()
    Cookies.set("Authorization",'')
    Cookies.set("user_name",'')
    history.replace("#/login");
    history.go()
    // navigator("/login")
}